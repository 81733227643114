const en = {
  mobileBar: {
    events: "Events",
    event: "Event",
    newEvent: "New event",
    tickets: "Tickets",
    dashboard: "Dashboard",
    cards: "Cards",
    cardList: "Card list",
    productList: "Product list",
    devices: "Devices",
    equipmentByEvent: "Equipment by Event",
    userGroups: "User groups",
    reports: "Reports",
    reportsList: {
      salesByProduct: "Sales by Product",
      salesByOperation: "Sales by Operation",
      salesByPaymentMethod: "Sales by Payment Method",
      salesByUser: "Sales by User",
      detailedSales: "Detailed Sales",
      salesByCashRegister: "Sales by Cash Register",
      salesByClient: "Sales by Client",
      financial: "Financial",
      courtesies: "Courtesies",
      reprints: "Reprints",
      refunds: "Refunds",
      balanceRefund: "Balance Refund",
      onlineRecharge: "Online Recharge",
      receivables: "Receivables",
      cashOutAndSupply: "Cash Out and Supply",
    },
    reportsTitle: {
      salesByProduct: "Sales Report by Product",
      salesByOperation: "Sales Report by Operation",
      salesByPaymentMethod: "Sales Report by Payment Method",
      salesByUser: "Sales Report by User",
      detailedSales: "Detailed Sales Report",
      salesByCashRegister: "Sales Report by Cash Register",
      salesByClient: "Sales Report by Client",
      financial: "Financial Report",
      courtesies: "Courtesy Report",
      reprints: "Reprints Report",
      refunds: "Refunds Report",
      balanceRefund: "Balance Refund Report",
      onlineRecharge: "Online Recharge Report",
      receivables: "Receivables Report",
      cashOutAndSupply: "Cash Out and Supply Report",
      listOfEquipmentByEvent: "List of Equipment by Event",
    },
    products: "Products",
    appointments: "Appointments",
    categories: "Categories",
    operations: "Operations",
    operationsList: "List of operations",
    users: "Users",
    userList: "User list",
    deviceStatus: "Device status",
    digitalWallet: "Digital Wallet",
    elevenDash: "ElevenCash Dashboard",
    statement: "Statement",
    personalData: "Personal Data",
    changePassword: "Change Password",
    balance: "Balance",
    new: "NEW",
  },
  status: {
    active: "Active",
    scheduled: "Scheduled",
    closed: "Closed",
    all: "All",
    total: "Total",
    selected: "Selected",
    available: "Available",
  },
  filter: {
    title: "Filters",
    rangeStart: "Start of Range",
    rangeEnd: "End of Range",
    applyFilter: "Apply Filters",
    document: "Document",
    cardCode: "Card Code",
    customer: "Customer",
    startDate: "Start Date",
    endDate: "End Date",
    user: "User",
    users: "Users",
    type: "Type",
    soldCourtesy: "Sold/Courtesy",
    equipment: "Equipment",
    operation: "Operation",
    operations: "Operations",
    paymentStatus: "Payment Status",
    transactionStatus: "Transaction Status",
    paymentMethods: "Payment Methods",
    name: "Name",
    telephone: "Phone",
    email: "Email",
    dateOfBirth: "Date of Birth",
    value: "Value",
    acquirer: "Acquirer",
  },
  form: {
    required: "Required",
    login: "Login",
    signup: "Sign Up",
    cancel: "Cancel",
    sendEmail: "Send Email",
    create: "Create",
    recoverPassword: "Recover Password",
    forgotPassword: "Forgot Password",
    newRegistration: "New Registration",
    newUser: "New User",
    editUser: "Edit User",
    newDevice: "New Device",
    editDevice: "Edit Device",
    import: "Import",
    importDevices: "Import Devices",
    importCSV: "Import CSV",
    selectCSVFile: "Select a CSV file",
    downloadCSVmodel: "Download CSV model",
    rules: {
      required: "Required field.",
      invalid: "Invalid field",
      email: "Invalid email.",
      upperLetters: "Use uppercase letters",
      lowerLetters: "Use lowercase letters",
      numbers: "Use numbers",
      specialCharacters: "Use special characters",
      minimum: "Minimum",
      maximum: "Maximum",
      characters: "characters",
      passwordsDontMatch: "Passwords do not match",
      requiredDocument: "Required document",
      invalidDocument: "Invalid document",
      startRefundDateRequired: "Refund start date required",
      invalidRefundPeriod: "Invalid refund period",
      endRefundDateRequired: "Refund end date required"
    },
    info: {
      login: "Please login",
    },
    alerts: {
      user: {
        logoff: "User successfully logged out!",
        login: "User successfully logged in!",
        signup: "Registration completed successfully!",
      },
      field: "Fill in the required field.",
      fields: "Fill in the required fields.",
      recaptcha: "Complete the reCAPTCHA.",
      dataChanged: "Data changed successfully!",
      passwordChanged: "Password changed successfully!",
      expiredSession: "Session expired, log in again to continue!",
      categoryRegistered: "Category registered successfully",
      categoryUpdated: "Category updated successfully",
      productRegistered: "Product successfully registered",
      productUpdated: "Product successfully updated",
    },
    label: {
      user: "User",
      password: "Password",
      fullName: "Full Name",
      phone: "Phone",
      telephone: "Telephone",
      email: "Email",
      currentPassword: "Current Password",
      newPassword: "New Password",
      confirmNewPassword: "Confirm New Password",
      canRefund: "Allow refund",
      serialNumber: "Serial number",
      identifier: "Identifier",
      description: "Description",
      deviceDescription: "Device description",
      deviceType: "Device type",
      tefTerminal: "TEF Terminal",
      event: "Event",
      eventName: "Event name",
      productName: "Product Name",
      selectRepresentative: "Select representative",
      eventStartDate: "Event start date",
      eventEndDate: "Event end date",
      eventImage: "Event image",
      operationImage: "Operation Image",
      operationInfo: "Operation information",
      groupInfo: "Group information",
      createOperationsWithPrefix: "Create one or more operations with the same prefix.",
      createAdminOperation: "Create default admin and attendant for all operations.",
      quantity: "Quantity",
      viewOnly: "View Only",
      canAccessReports: "Access POS reports",
      printMerchantReceipt: "Print payment receipt",
      printOperatorReceipt: "Operator receipt printing",
      printUserReceipt: "Customer receipt printing",
      canActivateCard: "Activate",
      canSellRecharge: "Recharge",
      canConsumeRecharge: "Cashless Sale",
      canSellOnlineRecharge: "Online recharge",
      canCashUpAndCashDown: "Cash Up Or Cash Down",
      searchOnlineRechargeOnCardAcquistion: "Search Online Recharge On Card Acquisition",
      searchOnlineRechargeOnSale: "Search Online Recharge On Sale",
      canSellGift: "Gift",
      canRefundBalance: "Balance Refund",
      canReplaceCard: "Replace Card",
      canTransferBalance: "Transfer Balance",
      canDisplayBalanceAndStatement: "Balance & Statement",
      requiresDocument: "Request Document",
      cashlessPrintType: "Print Type",
      cashlessPrintCopies: "Number of Cashless Copies",
      requiresContactInfo: "Request Contact Info",
      canSellTicket: "Sell Ticket",
      canConsumeTicket: "Burn Ticket",
      canAutoBurnTicket: "Automatic Ticket Burning",
      canReturnTicket: "Return Ticket",
      ticketPrintType: "Print Type",
      ticketCopyQuantity: "Number of Copies",
      print: "Print",
      doNotPrint: "Do not Print",
      toAsk: "To ask",
      profile: "Profile",
      masterCard: "Master Card",
      masterPIN: "Master PIN",
      integratorID: "Integrator ID",
      integratorIP: "Integrator IP",
      tokenIntegration: "Token Integration",
      category: "Category",
      categoryName: "Category name",
      ageRating: "Age rating",
      displayOrder: "Display order",
      icon: "Icon",
      insertImage: "Insert Image",
      consumption: "Consumption",
      dynamicValue: "Dynamic Value",
      useObservation: "Use Observation",
      observationTitle: "Observation Title",
      taxIntegrationCode: "Tax Integration Code",
      productType: "Product Type",
      cardOrBandID: "Card or Band ID",
      userSettings: "User Settings",
      operationSettings: "Operation Settings",
      eventSettings: "Event Settings",
      eventUsers: "Event Users",
      financialInformation: "Financial Information",
      changeImage: "Change Image",
      activationValue: "Activation Value",
      equipmentNumber: "Number of Equipments",
      occupiedLicenses: "Occupied Licenses",
      eventKey: "Event Key",
      useEncryption: "Use Encryption",
      refundStartDate: "Refund Start Date",
      refundEndDate: "Refund End Date",
      onlineRefundFee: "Online Refund Fee",
      customOnlineMenuURL: "Custom Online Menu URL",
      eventRepresentative: "Event Representative",
      noRegisteredRepresentatives: "No Registered Representatives",
      eventProducers: "Event Producers",
      noRegisteredProducers: "No Registered Producers",
      techniciansList: "Technicians List",
      companyData: "Company Data",
      individualPerson: "Individual Person",
      legalEntity: "Legal Entity",
      corporateName: "Corporate Name",
      postalCode: "Postal Code",
      address: "Address",
      fees: "Fees",
      fee: "Fee",
      transferRule: "Transfer Rule",
      revenuePercentage: "Revenue Percentage",
      bankDetails: "Bank Details",
      bank: "Bank",
      agency: "Agency",
      account: "Account",
      bankDetailsValidation: "Bank details must match the name/corporate name and CPF/CNPJ registered above",
      equipmentValue: "Equipment Value",
      posQuantity: "POS Quantity",
      posValue: "Value per POS",
      totemQuantity: "Totem Quantity",
      totemValue: "Value per Totem",
      totalEquipmentValue: "Total Equipment Value",
      serviceValue: "Service Value",
      freights: "Freights",
      technicianQuantity: "Technician Quantity",
      technicianValue: "Value per Technician",
      totalTechnicianValue: "Total Technician Value",
      cardBraceletValue: "Card/Bracelet Value",
      cardBraceletQuantity: "Card/Bracelet Quantity",
      cardBraceletUnitValue: "Value per Card/Bracelet",
      totalCardBraceletValue: "Total Card/Bracelet Value",
      chargesValue: "Charges Value",
      totalValueToCharge: "Total Value to Charge",
      eventFrequency: "What is the frequency of your event?",
      singleDate: "Single Date",
      recurringEventOrBusiness: "Recurring Event or Business",
      useDigitalWallet: "Use Digital Wallet?",
      paymentType: "Payment Type",
      automaticPaymentToProducer: "Automatic Payment to Producer",
      thirdPartyPayment: "Third Party Payment",
      notes: "Notes",
      fiscalEnvironment: "Fiscal Environment",
      maximumDiscountPOS: "Maximum discount value on POS",
      maxiumRefundAmount: "Maximum refund amount on POS",
      serviceFee: "Service Fee",
      defaultPassword: "Default Password",
      withAccess: "With access to the portal",
      withoutAccess: "Without access to the portal",
      operationUsers: "Operation Users",
      presets: "Presets",
      recurrenceType: "Recurrence Type",
      endTime: "End Time",
      startTime: "Start Time",
      appointmentHours: "Appointment Hours",
      noSetTime: "No set time",
    },
    tooltip: {
      readOnly: "Defines whether the user can only view, without being able to change values",
      canAccessReports: "Defines whether the user can access POS reports",
      printMerchantReceipt: "Defines whether the user can print the merchant's copy",
      printOperatorReceipt: "Defines the behavior of operator receipt printing when completing a transaction",
      printUserReceipt:
        "Defines the behavior of customer receipt printing when completing a transaction<br>" +
        "<ul>" +
        "<li>The following receipts will be affected:</li>" +
        "<li>Cashless recharge</li>" +
        "<li>Online cashless recharge</li>" +
        "<li>Balance refund and recharge</li>" +
        "<li>Balance transfer</li>" +
        "<li>Sale reversal for tokens and cashless</li>" +
        "<li>Token refund</li>" +
        "</ul>",
      canActivateCard: "Defines whether the user can activate cards on the POS",
      canSellRecharge: "Defines whether the user can recharge cards",
      canConsumeRecharge: "Defines whether the user can sell cashless products",
      canSellOnlineRecharge: "Defines whether the user can sell online recharges",
      canRefundBalance: "Defines whether the user can process refunds and receive balance or card refunds",
      canReplaceCard: "Defines whether the user can replace a card with another",
      canTransferBalance: "Defines whether the user can transfer balance from one card to another",
      canDisplayBalanceAndStatement: "Defines whether the user can view the card's balance and statement",
      requiresDocument: "Defines whether a document is required to activate the card",
      cashlessPrintType: "Defines the Cashless print type.",
      cashlessPrintCopies: "Defines the number of Cashless prints.",
      requiresContactInfo: "Defines if contact information is requested to activate the card",
      canSellTicket: "Defines if the user can sell tickets",
      canConsumeTicket: "Defines if the user can validate/burn tickets",
      canAutoBurnTicket: "Defines if the user can automatically burn tickets",
      canReturnTicket: "Defines if the user can return tickets",
      ticketPrintType: "Defines the type of ticket printing",
      ticketCopyQuantity: "Select the number of printed copies",
      canCashUpAndCashDown: "Determines if the user can or cannot manage register balance",
      searchOnlineRechargeOnCardAcquistion: "Check if the card code or document entered has online recharges linked at the time of activation",
      searchOnlineRechargeOnSale: "Check if the card code or document has online top-ups linked when the card is out of balance",
      exportPDF: "Export PDF",
      exportCSV: "Export CSV",
      exportBordereau: "Export event bordereau",
      importCSV: `<small><span>Here are some tips to help you import your products without any issues.</span><ul><li>Set the separator option to semicolon ";" in the spreadsheet.</li><li>Ensure the product name, category, or value are correct.</li><li>The product values must be in cents, only numbers, without characters such as dots or commas. Example: R$1,00 > 100</li></ul></small>`
    },
    placeholder: {
      user: "Enter user",
      password: "Enter password",
      document: "Enter document",
      fullName: "Enter name",
      phone: "Enter phone",
      email: "Enter email",
      eventSearch: "Search by event name",
      proofOfTransfer: "Enter proof of transfer",
      general: "General",
      ticket: "Ticket",
      productName: "Enter the product name",
      categoryName: "Enter the category name",
      operationName: "Enter the operation name",
      selectCategory: "Select the category",
      maximumSize: "Maximum size 2MB",
      productValue: "Enter the product value",
      productConsumption: "Enter the product consumption",
      notes: "Enter notes about the event, extra charges, equipment loss, etc.",
    },
    options: {
      specificDate: "Specific Date",
      recurring: "Recurring",
      activePeriod: "Active Period",
      inactivePeriod: "Inactive Period",
      entry: "Entry",
      exit: "Exit"
    }
  },
  alt: {
    thinkingPerson: "Image of a person thinking.",
  },
  cardTitle: {
    totalSold: "Total Sold",
    totalConsumed: "Total Consumed",
    toBeConsumed: "To Be Consumed",
    ranking: {
      products: "Product Ranking",
      clients: "Client Ranking",
      operations: "Operations Ranking",
    },
    paymentMethod: "Payment Methods",
    cardsAndBands: "Cards/Bands",
    devices: "Devices",
    otherTransactions: "Other Transactions",
    consumptionPerHour: "Consumption Per Hour",
    details: "Details",
    updateRegisteredData: "Update registered data",
  },
  header: {
    from: "From",
    until: "Until",
    to: "to",
    seconds: "seconds",
    date: "Date",
    hour: "Hour",
    updating: "Updating...",
    updatingIn: "Updating in",
    updatingData: "Updating data in",
    noSetDate: "No date set",
    noLinkedEvent: "User does not have a linked event.",
    noLinkedCard: "User does not have a linked card.",
    noEventFound: "No events found.",
    noResultFound: "No result found.",
    resultsPerPage: "Results per page",
    noHistory: "No history to display",
  },
  ageClassification: {
    free: "Free of restriction",
    prohibitedUnder: "Prohibited for minors under {age} years old",
  },
  icons: {
    drinks: "Drinks",
    food: "Food",
    souvenirs: "Souvenirs",
    car: "Car (Parking)",
    cocktail: "Cocktails",
    others: "Others",
  },
  weekDays: {
    title: "Days of the week",
    everyDay: "Every Day",
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
  },
  actions: {
    actions: "Actions",
    yes: "Yes",
    no: "No",
    optional: "Optional",
    notice: "Notice",
    search: "Search",
    apply: "Apply",
    save: "Save",
    confirm: "Confirm",
    register: "Register",
    saveRegistration: "Save Registration ",
    clear: "Clear",
    next: "Next",
    edit: "Edit",
    delete: "Delete",
    remove: "Remove",
    duplicate: "Duplicate",
    checkAll: "Check All",
    uncheckAll: "Uncheck All",
    addAll: "Add all",
    removeAll: "Remove all",
    editEvent: "Edit event",
    duplicateEvent: "Duplicate event",
    deleteEvent: "Delete event",
    newGroup: "New Group",
    newProduct: "New Product",
    newAppointment: "New Appointment",
    editProduct: "Edit Product",
    importSpreadsheet: "Import Spreadsheet",
    onlineMenu: "Online Menu",
    copyLink: "Copy Link",
    download: "Download",
    newCategory: "New Category",
    newOperation: "New Operation",
    createOperations: "Create Operations",
    configureOperations: "Configure Operations",
    addOperations: "Add Operations",
    editOperations: "Edit Operations",
    addUsers: "Add Users",
    addUsersInBulk: "Add Users in Bulk",
    addUsersInBulkDescription: "Create multiple users with the same configuration for all operations at once.",
    noRegisteredOperations: "No registered operations",
    editCategory: "Edit Category",
    notDefined: "Action not defined",
    deleteEventConfirmation: "Do you want to delete the event {event}?",
    requestRefund: "Request Refund",
    refundRequest: "Refund Request",
    refundDeadline: "The deadline for requesting a refund is from",
    balanceToBeRefunded: "Balance to be refunded",
    refundFee: "Refund Fee",
    deactivateCategory: "Deactivate Category",
    categoryDeactivationConfirmation: "Deactivating the category <strong> {category} </strong> may affect the availability of several products. Press CONFIRM to confirm the action.",
    pixTransfer: "A PIX transfer will be made to the document linked to your registration.<br><br>The PIX CPF key is the best way to ensure that the applicant will be the beneficiary of the refund.<u>Make sure your CPF is registered and able to receive transactions of this type.</u><br><br>If you do not want to keep it, the key can be deactivated once the refund is confirmed.",
    cpfNotice: "The registration must be made using the CPF of the consumer who activated the bracelet or card.",
    selectCard: "Select a card",
    confirmTransferRequest: "Confirm the transfer request worth",
    finalizeRequest: "Finalize request",
    processingRequest: "Processing request",
    directedToRanking: "Click here to be directed to the report regarding this ranking.",
    searchForInformation: "Search for information",
    searchForWithdrawals: "Search for withdrawals",
    requestWithdrawal: "Request withdrawal",
    pay: "Pay",
    payThirdParties: "Pay third parties",
    normal: "Normal",
    gift: "Gift",
    refundSuccess: "Refund successfully requested",
    ok: "Ok"    
  },
  digitalWallet: {
    availableBalance: "Available balance",
    heldBalance: "Held balance",
    waitingForRelease: "Waiting for release",
    transferredAmount: "Transferred amount",
    totalRevenue: "Total revenue",
    elevenInvoices: "Eleven invoices",
    acquirerFees: "Acquirer fees",
    waitingForAcquirerRelease: "Waiting for acquirer release.",
    amountTransferredToProducer: "Amount already transferred to the producer.",
    netRevenueAfterFees: "Net revenue after acquirer fees.",
    availableBalanceForWithdrawal: "Available balance for withdrawal",
    withdrawalProcessingInfo: "All requested transfers (withdrawal or pay third parties) will be processed within 48 business hours.",
    withdrawalAmount: "Withdrawal amount",
    description: "Description",
    payThirdPartiesInfo: "Make payments to suppliers and third-party accounts using your available balance.",
    paymentAmount: "Payment amount",
    accountDataForPayment: "Account data for payment",
    recipient: "Recipient",
    insertRecipient: "Insert recipient",
    checkingAccount: "Checking account",
    savingsAccount: "Savings account",
    createPaymentIdentifier: "Create a payment identifier.",
    insertTransferReceipt: "Insert transfer receipt",
    emailCodeValidation: "Email code validation",
    enterCodeToFinish: "Enter the code sent to the registered email to complete the operation",
    tokenCode: "Token Code",
    insertCodeSentByEmail: "Enter the code sent by email",
    cancelTransferRequest: "Do you want to <span>cancel</span> the transfer request in the amount of <span>R$ {value}</span>?",
    rejectTransferRequest: "Do you want to <span>reject</span> the transfer request in the amount of <span>R$ {value}</span>?",
    rejectionReason: "Rejection Reason",
    informRejectionReason: "Provide the reason for rejection.",
    cancellationReason: "Cancellation Reason",
    informCancellationReason: "Provide the reason for cancellation.",
    giveUp: "Give up",
    reject: "Reject",
    noDataToView: "No data to view.",
    noDataFound: "No data found.",
    noInfoToGenerateFile: "No information to generate the file",
    searchingWithdrawals: "Searching for withdrawals.",
  },
  schedule: {
    creation: {
      success: "Appointment successfully created",
      failure: "Failed to create appointment",
    },
    editing: {
      success: "Appointment successfully edited",
      failure: "Failed to edit appointment",
    },
    deletion: {
      success: "Appointment successfully deleted",
      failure: "Failed to delete appointment",
    },
    list: {
      failure: "Failed to list appointments",
    },
  },
  register: {
    open: {
      fail: "Failed to open record",
    },
    value: {
      fail: "Failed to register value",
    },
  },
  cards: {
    reason: "Reason",
    blockCard: "Block card",
    selectReason: "Select blocking reason",
    cardHistory: "Card history",
    inUse: {
      failure: "Failed to list cards in use",
    },
    balance: {
      failure: "Failed to check card balance",
    },
    search: {
      error: "Error fetching card",
    },
    block: {
      block: "Block",
      success: "Card successfully blocked",
      failure: "Failed to block card",
    },
  },
  wallet: {
    values: {
      failure: "Failed to check wallet values",
    },
  },
  category: {
    search: {
      failure: "Failed to fetch categories",
    },
    creation: {
      success: "Category successfully created",
      failure: "Failed to create category",
    },
    editing: {
      success: "Category successfully edited",
      failure: "Failed to edit category",
    },
    deletion: {
      success: "Category successfully deleted",
      failure: "Failed to delete category",
    },
    restoration: {
      success: "Category successfully restored",
      failure: "Failed to restore category",
    },
  },
  config: {
    editing: {
      failure: "Failed to edit settings",
    },
    get: {
      failure: "Failed to retrieve settings",
    },
  },
  bankingAccount: {
    listActive: {
      failure: "Failed to list active accounts",
    },
    creation: {
      failure: "Failed to create bank account",
    },
    listByPerson: {
      failure: "Failed to list accounts by person",
    },
    changeAccountStatus: {
      failure: "Failed to change account status",
    },
    update: {
      failure: "Failed to update bank account",
    },
  },
  equipment: {
    list: {
      failure: "Failed to list equipment",
    },
    status: {
      failure: "Failed to check equipment status",
    },
    creation: {
      success: "Equipment successfully created",
      failure: "Failed to create equipment",
    },
    listByEvent: {
      failure: "Failed to list equipment by event",
    },
    listAcquirers: {
      failure: "Failed to list equipment acquirers",
    },
  },
  event: {
    refund: {
      notAllowed: "Refunds are not allowed for this event"
    },
    massEquipmentImport: {
      success: "Mass equipment import successfully completed",
      failure: "Failed mass equipment import",
    },
    list: {
      failure: "Failed to list events",
    },
    listById: {
      failure: "Failed to list event by ID",
    },
    listByOperatorId: {
      failure: "Failed to list events by operator",
    },
    dates: {
      failure: "Failed to check event dates",
    },
    editing: {
      success: "Event successfully edited",
      failure: "Failed to edit event",
    },
    creation: {
      success: "Event successfully created",
      failure: "Failed to create event",
    },
    deletion: {
      success: "Event successfully deleted",
      failure: "Failed to delete event",
    },
    duplication: {
      success: "Event successfully duplicated",
      failure: "Failed to duplicate event",
    },
    licenses: {
      failure: "Failed to check event licenses",
    },
    menu: {
      failure: "Failed to load event menu",
    },
  },
  paymentMethod: {
    list: {
      failure: "Failed to list payment methods",
    },
  },
  gateway: {
    list: {
      failure: "Failed to list gateways",
    },
  },
  management: {
    dailyRevenue: {
      failure: "Failed to check daily revenue",
    },
    topEvents: {
      failure: "Failed to check top events",
    },
    cardsAndBracelets: {
      failure: "Failed to check cards and wristbands",
    },
    paymentMethodRank: {
      failure: "Failed to check payment method ranking",
    },
    totalTransacted: {
      failure: "Failed to check total transactions",
    },
    revenue: {
      failure: "Failed to check revenue",
    },
  },
  graph: {
    hourConsumption: {
      failure: "Failed to check hourly consumption",
    },
  },
  userGroup: {
    creation: {
      failure: "Failed to create user group",
      success: "User group created successfully"
    },
    deletion: {
      success: "User group successfully deleted",
      failure: "Failed to delete user group",
    },
    restoration: {
      success: "User group successfully restored",
      failure: "Failed to restore user group",
    },
    editing: {
      success: "User group successfully edited",
      failure: "Failed to edit user group",
    },
    list: {
      failure: "Failed to list user groups",
    },
    link: {
      success: "User successfully linked to group",
      failure: "Failed to link user to group",
    },
    unlink: {
      success: "User successfully unlinked from group",
      failure: "Failed to unlink user from group",
    },
  },
  indicators: {
    grossAmount: {
      failure: "Failed to check gross value",
    },
    grossAmountDetails: {
      failure: "Failed to check gross value details",
    },
    totalConsumed: {
      failure: "Failed to check total consumption",
    },
    lackConsumption: {
      failure: "Failed to check consumption absence",
    },
    lackConsumptionDetails: {
      failure: "Failed to check consumption absence details",
    },
  },
  fakeEvent: {
    verifyDomainAvailability: {
      failure: "Failed to check domain availability",
    },
    creation: {
      failure: "Failed to create fake event",
    },
    listProducers: {
      failure: "Failed to list producers",
    },
    collectProducer: {
      failure: "Failed to gather producer data",
    },
    listAllEvents: {
      failure: "Failed to list all events",
    },
    listEventsProducer: {
      failure: "Failed to list producer's events",
    },
    deletion: {
      failure: "Failed to delete fake event",
    },
  },
  operations: {
    list: {
      failure: "Failed to list operations",
    },
    get: {
      failure: "Failed to check operation",
    },
    editing: {
      success: "Operation successfully edited",
      failure: "Failed to edit operation",
    },
    deletion: {
      success: "Operation successfully deleted",
      failure: "Failed to delete operation",
    },
    massCreation: {
      success: "Mass operations creation successfully completed",
      failure: "Failed mass operations creation",
    },
    duplication: {
      success: "Operation successfully duplicated",
      failure: "Failed to duplicate operation",
    },
  },
  profile: {
    list: {
      failure: "Failed to list profiles",
    },
  },
  person: {
    login: {
      notFound: "User not found. Please sign up to continue.",
      invalidCredentials: "Invalid CPF or password. Please try again.",
      failure: "Failed to log in",
    },
    personEnv: {
      failure: "Failed to check person's environment",
    },
    creation: {
      failure: "Failed to create person",
    },
    get: {
      failure: "Failed to check person",
    },
    update: {
      failure: "Failed to update person",
    },
    changePassword: {
      success: "Password successfully changed",
      failure: "Failed to change password",
    },
    recoverPassword: {
      success: "An e-mail to reset your credentials has been sent to the address linked to your document",
      failure: "Failed to recover access",
    },
  },
  product: {
    list: {
      failure: "Failed to list products",
    },
    creation: {
      deletion: "Product created and deleted",
      failure: "Failed to create product",
    },
    massCreation: {
      failure: {
        invalidContent: "Failed mass creation: invalid content",
      },
      success: "Mass product creation successfully completed",
      failure: "Failed mass product creation",
    },
    editing: {
      success: "Product successfully edited",
    },
    deletion: {
      success: "Product successfully deleted",
      failure: "Failed to delete product",
    },
    restoration: {
      success: "Product successfully restored",
      failure: "Failed to restore product",
    },
    getById: {
      failure: "Failed to fetch product by ID",
    },
    ocult: {
      success: "Product successfully hidden",
    },
    menu: {
      success: "Product menu successfully loaded",
    },
  },
  withdraw: {
    creationProducer: {
      success: "Withdrawal successfully created for producer",
    },
    creationThirdParty: {
      success: "Withdrawal successfully created for third parties",
      failure: "Failed to create withdrawal for third parties",
    },
    auth: {
      success: "Withdrawal authorization successfully completed",
      failure: "Failed withdrawal authorization",
    },
    cancel: {
      success: "Withdrawal successfully canceled",
      failure: "Failed to cancel withdrawal",
    },
    approve: {
      success: "Withdrawal successfully approved",
      failure: "Failed to approve withdrawal",
    },
    reject: {
      success: "Withdrawal successfully rejected",
      failure: "Failed to reject withdrawal",
    },
    editing: {
      success: "Withdrawal successfully edited",
      failure: "Failed to edit withdrawal",
    },
  },
  sync: {
    transactions: {
      failure: "Failed to sync transactions",
    },
    mov: {
      failure: "Failed to sync movements",
    },
    cards: {
      failure: "Failed to sync cards",
    },
    tokens: {
      failure: "Failed to sync tokens",
    },
    person: {
      failure: "Failed to sync person data",
    },
  },
  user: {
    login: {
      notFound: "User not found. Please sign up to continue.",
      invalidCredentials: "Invalid CPF or password. Please try again.",
      failure: "Failed to log in user",
    },
    list: {
      failure: "Failed to list users",
    },
    getById: {
      failure: "Failed to fetch user by ID",
    },
    creation: {
      success: "User successfully created",
      failure: "Failed to create user",
    },
    deletion: {
      success: "User successfully deleted",
      failure: "Failed to delete user",
    },
    editing: {
      success: "User successfully edited",
      failure: "Failed to edit user",
    },
    restoration: {
      success: "User successfully restored",
      failure: "Failed to restore user",
    },
    changePassword: {
      success: "User password successfully changed",
      failure: "Failed to change user password",
    },
  },
  categories: {
    search: {
      id: "ID",
      category: "Category",
      icon: "Icon",
      order: "Order",
      ageClassification: "Age Classification",
      active: "Active",
    }
  },
  cardList: {
    statement: {
      code: "Code",
      document: "Document",
      client: "Client",
      balance: "Balance",
      status: "Status",
    }
  },
  group: {
    search: {
      id: "ID",
      description: "Description",
    }
  },
  report: {
    productSales: {
      category: "Category",
      product: "Product",
      sold: "Sold",
      refunded: "Refunded",
      total: "Total",
      unitValue: "Unit Value",
      totalValue: "Total Value",
    },
    operationSales: {
      operation: "Operation",
      category: "Category",
      product: "Product",
      sold: "Sold",
      refunded: "Refunded",
      total: "Total",
      unitValue: "Unit Value",
      totalValue: "Total Value",
    },
    paymentMethodSales: {
      equipment: "Equipment",
      cash: "Cash",
      debit: "Debit",
      credit: "Credit",
      pix: "Pix",
      banricompras: "Banricompras",
      courtesyAndRechage: "Recharge - Courtesy",
      activation: "Activation",
      activationCourtesy: "Activation - Courtesy",
      other: "Other",
      value: "Value",
      consumption: "Consumption",
      courtesyConsumption: "Courtesy Consumption",
    },
    userSales: {
      operator: "Operator",
      cash: "Cash",
      debit: "Debit",
      credit: "Credit",
      pix: "Pix",
      banricompras: "Banricompras",
      activation: "Activation",
      discount: "Discounts",
      serviceFee: "Service Fee",
      other: "Other",
      value: "Value",
      consumption: "Consumption",
    },
    detailed: {
      movId: "Movement ID",
      registerId: "Register ID",
      creationDate: "Creation Date",
      lastUpdated: "Last Updated",
      type: "Type",
      equipment: "Equipment",
      operation: "Operation",
      operator: "Operator",
      amountPaid: "Amount Paid",
      amountConsumed: "Amount Consumed",
      paymentStatement: "Payment Statement",
      transactionStatement: "Transaction Statement",
    },
    detailedPayments: {
      movId: "Movement ID",
      paymentId: "Payment ID",
      registerId: "Register ID",
      creationDate: "Creation Date",
      lastUpdate: "Last Update",
      equipment: "Equipment",
      operation: "Operation",
      operator: "Operator",
      type: "Type",
      paymentMethod: "Payment Method",
      value: "Value",
      total: "Total",
      paymentStatus: "Payment Status",
      card: "Card",
      document: "Document",
      balanceInCard: "Card Balance",
      cardSequential: "Card Sequential",
      pdvSequential: "PDV Sequential",
      supervisor: "Supervisor",
      details: "Details",
      nsu: "NSU",
      flag: "Flag",
      tefReturn: "Receipt"
    },
    detailedTransactions: {
      movId: "Movement ID",
      transactionId: "Transaction ID",
      registerId: "Register ID",
      creationDate: "Creation Date",
      lastUpdate: "Last Update",
      equipment: "Equipment",
      operation: "Operation",
      operator: "Operator",
      type: "Type",
      value: "Value",
      status: "Status",
      product: "Product",
      productCategory: "Product Category",
      supervisor: "Supervisor",
      card: "Card",
      document: "Document",
      balanceInCard: "Card Balance",
    },
    register: {
      operation: "Operation",
      user: "User",
      equipment: "Equipment",
      opening: "Opening",
      closing: "Closing",
      cash: "Cash",
      debit: "Debit",
      credit: "Credit",
      pix: "Pix",
      onlineRecharge: "Online Recharge",
      rechargeTotal: "Recharge Total",
      totalTokenSold: "Total Tokens Sold",
      totalConsumed: "Total Consumed",
      supplyRegister: "Cash Supply",
      cashFloat: "Cash Float",
      inHandsCash: "Cash in Hands",
      tokenRefund: "Token Refund",
      balanceRefund: "Balance Refund",
      courtesyRecharge: "Courtesy Recharge",
      courtesyConsumption: "Courtesy Consumption",
      bonus: "Bonus",
      advanced: "Advanced",
      voucher: "Voucher",
      other: "Other",
      registerId: "Register ID",
    },
    clients: {
      name: "Name",
      document: "Document",
      telephone: "Phone",
      email: "Email",
      dateOfBirth: "Date of Birth",
      maxValueSpent: "Maximum Value Spent",
    },
    finance: {
      equipment: "Equipment",
      cash: "Cash",
      debit: "Debit",
      credit: "Credit",
      totalCredit: "Total Credit",
      pix: "Pix",
      onlineRecharge: "Online Recharge",
      totalSold: "Total Sold",
      totalTokenSold: "Total Tokens Sold",
      totalConsumed: "Total Consumed",
      registerSupply: "Cash Supply",
      cashFloat: "Cash Float",
      inHandsCash: "Cash in Hands",
      tokenRefund: "Token Refund",
      balanceRefund: "Balance Refund",
      courtesyRecharge: "Courtesy Recharge",
      courtesyConsumption: "Courtesy Consumption",
      bonus: "Bonus",
      advanced: "Advanced",
      voucher: "Voucher",
      other: "Other",
    },
    courtesy: {
      equipment: "Equipment",
      operation: "Operation",
      user: "User",
      supervisor: "Supervisor",
      dateTime: "Date/Time",
      card: "Card",
      name: "Name",
      document: "Document",
      type: "Type",
      product: "Product",
      value: "Value",
    },
    reprinting: {
      supervisor: "Supervisor",
      equipment: "Equipment",
      operation: "Operation",
      user: "User",
      dateTime: "Date/Time",
      card: "Card",
      document: "Document",
      type: "Type",
      status: "Status",
      product: "Product",
      value: "Value",
    },
    productRefund: {
      equipment: "Equipment",
      operation: "Operation",
      operator: "Operator",
      dateTime: "Date/Time",
      cardId: "Card ID",
      cpf: "CPF",
      type: "Type",
      product: "Product",
      value: "Value",
    },
    balanceRefund: {
      equipment: "Equipment",
      operation: "Operation",
      operator: "Operator",
      product: "Product",
      dateTime: "Date/Time",
      cardId: "Card ID",
      cpf: "CPF",
      type: "Type",
      refundedValue: "Refunded Value",
    },
    cardOnlineRecharge: {
      dateTime: "Date and Time",
      document: "Document",
      card: "Card",
      name: "Name",
      value: "Value",
      status: "Status",
    },
    receivables: {
      operator: "Operator",
      credit: "Credit",
      liquidCredit: "Net Credit",
      debit: "Debit",
      liquidDebit: "Net Debit",
      pix: "PIX",
      liquidPix: "Net PIX",
      total: "Total",
      liquidTotal: "Net Total",
    },
    cashFloatAndRegisterSupply: {
      dateTime: "Date and Time",
      supervisor: "Supervisor",
      equipment: "Equipment",
      operator: "Operator",
      paymentType: "Payment Type",
      value: "Value",
    },
    products: {
      id: "ID",
      categoryId: "Category ID",
      image: "Image",
      product: "Product",
      category: "Category",
      value: "Value",
      type: "Type",
      active: "Active",
      qrCode: "QR Code",
      useObservation: "Uses Observation",
      observationTitle: "Observation Title",
      enabled: "Enabled",
    },
    operations: {
      id: "ID",
      fantasy: "Fantasy",
      reason: "Reason",
    },
    equipmentByEvent: {
      equipment: "Equipment",
      equipmentId: "Equipment ID",
      event: "Event",
      initialDate: "Start Date",
      finalDate: "End Date",
      acquirer: "Acquirer",
      eventId: "Event ID",
    },
    withdrawals: {
      identifier: "Identifier",
      updateDate: "Update Date",
      applicant: "Applicant",
      status: "Status",
      approval: "Approval",
      withdrawalValue: "Withdrawal Value",
      withdrawalId: "Withdrawal ID",
      document: "Document",
      approvalDate: "Approval Date",
      receipt: "Receipt",
      justification: "Justification",
      observation: "Observation",
    },
    users: {
      id: "ID",
      name: "Name",
      user: "User",
      profile: "Profile",
      operation: "Operation",
      creation: "Creation",
      active: "Active",
    },
    equipmentStatus: {
      user: "User",
      equipment: "Equipment",
      batteryLevel: "Battery Level",
      charging: "Charging",
      registerOpen: "Register Open",
      appVersion: "App Version",
      networkType: "Network Type",
      signalStrength: "Signal Strength",
      ssid: "SSID",
      ip: "IP",
      transactionCount: "Transaction Count",
      notSyncedTransactionCount: "Unsynced Transactions",
      lastCommunication: "Last Communication",
      uptime: "Uptime",
      latency: "Latency",
      registerBalance: "Register Balance",
      latitude: "Latitude",
      longitude: "Longitude",
      applications: "Applications",
      acquirer: "Acquirer",
    },
    attendant: {
      attendant: "Attendant",
      totalValue: "Total Value",
      percentage: "Percentage",
    },
    cardStatement: {
      card: "Card",
      document: "Document",
      date: "Date",
      item: "Item",
      value: "Value",
      balance: "Balance",
      paymentMethod: "Payment Method",
      operator: "Operator",
      operation: "Operation",
      paymentStatus: "Payment Status",
      transactionStatus: "Transaction Status",
      effect: "Effect",
      equipment: "Equipment",
    },
  },
  enum: {
    blockReason: {
      negativeBalance: "Negative balance",
      maxWebRecharge: "Recharge value exceeds the maximum allowed for Web",
      minWebRecharge: "Recharge value below the minimum allowed for Web",
      maxPDVRecharge: "Recharge value exceeds the maximum allowed for PDV",
      minPDVRecharge: "Recharge value below the minimum allowed for PDV",
      maxTCIRecharge: "Recharge value exceeds the maximum allowed for TCI",
      minTCIRecharge: "Recharge value below the minimum required for TCI",
      overLimitBalance: "Balance exceeds the allowed limit",
      documentMissing: "Card without linked document",
      lose: "Loss",
      theft: "Theft",
      equipmentMissing: "Card without or with invalid linked equipment",
      webRefund: "Refund",
      balanceTransference: "Balance transfer",
      cardReuse: "Card Reuse (Unlocking)",
      damagedCard: "Damaged Card",
    },
    cardStatus: {
      freeCard: "Free",
      active: "Active",
      blocked: "Blocked",
      blockedBlacklist: "Blocked - Blacklist",
      blockedCrc: "Blocked - Cyclic Redundancy Check",
      blockedIncorrectPassword: "Blocked - Incorrect Password",
      blockedCardReturned: "Blocked - Card was returned",
      dateOfBirthChanged: "Date of birth was changed",
      passwordChanged: "Password was changed",
      restrictionChanged: "Restriction",
    },
    cardType: {
      standard: "Standard card",
      courtesy: "Courtesy card",
    },
    pixKeyType: {
      email: "Email",
      cpf: "CPF",
      cnpj: "CNPJ",
      cellphone: "Phone",
      random: "Random key",
    },
    bankAccountType: {
      currentIndividual: "Individual Current Account",
      currentBusiness: "Business Current Account",
      savingsIndividual: "Individual Savings Account",
      savingsBusiness: "Business Savings Account",
    },
    acquirer: {
      pagseguro: "PagSeguro",
      vero: "Vero",
      softwareexpress: "SoftwareExpress",
      auttar: "Auttar",
      unknown: "Not informed",
    },
    equipmentType: {
      server: "SERVER",
      parkingMeter: "PARKING METER",
      turnstile: "TURNSTILE",
      barrier: "BARRIER",
      tps: "TPS",
      parkingSensor: "PARKING SENSOR",
      tci: "TOTEM",
      pdv: "smartPOS",
      supermedia: "SUPERMEDIA",
      maestroTotemViewer: "MAESTRO TOTEM / MAESTRO VIEWER",
      maestroEvaluator: "MAESTRO EVALUATOR",
      maestroMobileCaller: "MAESTRO MOBILE CALLER",
      multiKiosk: "MULTI KIOSK",
      weatherStation: "WEATHER STATION",
      bowlingLane: "BOWLING LANE",
      bowlingPinIndicator: "BOWLING PIN INDICATOR",
      bowlingReservation: "BOWLING RESERVATION",
      bowlingOnOff: "BOWLING ON/OFF",
      bowlingBar: "BOWLING BAR",
      parkingConcentrator: "PARKING CONCENTRATOR",
      mobilePointOfSale: "MOBILE POS",
      handheld: "HANDHELD",
      other: "Other",
    },
    cardAction: {
      read: "Read data",
      write: "Write data",
      auth: "Authenticate sector",
    },
    cardData: {
      cardNotFormatted: "Card not formatted",
    },
    keyType: {
      A: "Use key A",
      B: "Use key B",
    },
    cardDocumentType: {
      cpf: "Active card with CPF",
      free: "Active card without document",
    },
    movType: {
      activation: "Card acquisition",
      recharge: "Cashless recharge",
      cashlessSale: "Cashless consumption",
      tokenSale: "Token Sale",
      onlineRecharge: "Online recharge",
      cardReplacement: "Card replacement",
      cashSupply: "Cash supply",
      cashWithdrawal: "Cash withdrawal",
      refund: "Refund",
      balanceTransfer: "Balance transfer",
      tokenReturn: "Token return",
      cardBlock: "Card blocking",
      onlineRefund: "Online refund",
      other: "-",
    },
    paymentStatus: {
      success: "Success",
      canceled: "Canceled",
      pending: "Pending",
      reversed: "Reversed",
      available: "Available",
      reserved: "Reserved",
    },
    paymentType: {
      recharge: "Recharge",
      cardAcquisition: "Card acquisition",
      cardReturn: "Activation return",
      refundedBalance: "Balance refund",
      consumption: "Consumption",
      tokenIssuance: "Token issuance",
      tokenRedemption: "Token redemption",
      refundInProgress: "Refund in progress",
      cancelledRefund: "Cancelled refund",
      refundFee: "Refund fee",
      productRefund: "Product refund",
      balanceTransfer: "Balance transfer",
      postPaidConsumption: "Post-paid consumption",
      paymentRefund: "Payment refund",
      cardReuse: "Card reuse",
      withdrawalTransfer: "Withdrawal transfer",
      applicationTransfer: "Application transfer",
      tokenReturn: "Token return",
      cashlessReprint: "Cashless reprint",
      tokenReprint: "Token reprint",
      cardBlockRegistration: "Card block registration",
      cashlessConsumptionRefund: "Cashless consumption refund",
      fractioningOperation: "Fractioning operation",
      cashboxOpening: "Cashbox opening",
      cashWithdrawal: "Cash withdrawal",
      cashSupply: "Cash supply",
      cashboxClosing: "Cashbox closing",
      cashBreakage: "Cash breakage",
      cashSurplus: "Cash surplus",
      cashboxClosure: "Cashbox closure",
      feeTransfer: "Transfer fee",
      rentalFee: "Rental fee",
      minimumFee: "Minimum fee",
      technicalHourFee: "Technical hour fee",
      serviceFee: "Service fee",
      discount: "Discount",
      rechargeRefund: "Recharge refund",
      default: "Payment",
    },
    paymentMethod: {
      antecipado: "Advance",
      "-": "-",
      cortesia: "Courtesy",
      credito: "Credit",
      ficha: "Token",
      dinheiro: "Cash",
      pix: "Pix",
      debito: "Debit",
      brinde: "Gift",
      cashless: "Cashless",
      banricompras: "Banricompras",
      credito_parcelado: "Installment Credit",
      bonus: "Bonus",
      recarga_online: "Online Recharge",
      outros: "Others",
    },
    productObservation: {
      yes: "Yes",
      optional: "Optional",
      no: "No",
    },
    refundSituation: {
      finished: "Refund completed",
      waitingForConfirmation: "Refund waiting for confirmation",
      inProgress: "Refund in progress",
      incorrectData: "Incorrect refund data",
      rejected: "Refund rejected",
    },
    withdrawalStatus: {
      pending: "Pending",
      approved: "Approved",
      cancelled: "Cancelled",
      rejected: "Rejected",
      waitingForAuthentication: "Waiting for Authentication",
    },
    transactionStatus: {
      success: "Success",
      canceled: "Canceled",
      pending: "Pending",
      reversed: "Reversed",
      available: "Available",
      reserved: "Reserved",
    },
    transactionType: {
      recharge: "Recharge",
      cashlessActivation: "Cashless Activation",
      cashlessActivationReturn: "Cashless Activation Return",
      cashlessBalanceReturn: "Cashless Balance Return",
      consumption: "Consumption",
      tokenIssuance: "Token Issuance",
      tokenRedemption: "Token Redemption",
      onlineRefund: "Online Refund",
      cancelledRefund: "Cancelled Refund",
      refundFee: "Refund Fee",
      productRefund: "Product Refund",
      balanceTransfer: "Balance Transfer",
      postPaidConsumption: "Post-Paid Consumption",
      paymentRefund: "Payment Refund",
      cardReuse: "Card Reuse",
      withdrawalTransfer: "Withdrawal Transfer",
      applicationTransfer: "Application Transfer",
      tokenReturn: "Token Return",
      cashlessReprint: "Cashless Reprint",
      tokenReprint: "Token Reprint",
      cardBlockRegistration: "Card Block Registration",
      cashlessConsumptionRefund: "Cashless Consumption Refund",
      fractioningOperation: "Fractioning Operation",
      cashboxOpening: "Cashbox Opening",
      cashWithdrawal: "Cash Withdrawal",
      cashSupply: "Cash Supply",
      cashboxClosing: "Cashbox Closing",
      cashBreakage: "Cash Breakage",
      cashSurplus: "Cash Surplus",
      cashboxClosure: "Cashbox Closure",
      feeTransfer: "Fee Transfer",
      rentalFee: "Rental Fee",
      minimumFee: "Minimum Fee",
      technicalHourFee: "Technical Hour Fee",
      serviceFee: "Service Fee",
      discount: "Discount",
      rechargeRefund: "Recharge Refund",
    },
    useRole: {
      eleven: "ELEVEN",
      imply: "IMPLY",
      representative: "REPRESENTATIVE",
      technician: "TECHNICIAN",
      productionAdmin: "PRODUCTION ADMINISTRATOR",
      operationAdmin: "OPERATION ADMINISTRATOR",
      attendant: "ATTENDANT",
      other: "-",
    },
    documentType: {
      cpf: "CPF",
      passport: "Passport",
      foreign: "Foreign",
      other: "-",
    },
  },
};

export default en;
