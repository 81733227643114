const es = {
  mobileBar: {
    events: "Eventos",
    event: "Evento",
    newEvent: "Nuevo evento",
    tickets: "Entradas",
    dashboard: "Dashboard",
    cards: "Tarjetas",
    cardList: "Lista de tarjetas",
    productList: "Lista de productos",
    devices: "Dispositivos",
    equipmentByEvent: "Equipo por evento",
    userGroups: "Grupos de usuarios",
    reports: "Informes",
    reportsList: {
      salesByProduct: "Venta por producto",
      salesByOperation: "Venta por operación",
      salesByPaymentMethod: "Venta por método de pago",
      salesByUser: "Venta por usuario",
      detailedSales: "Venta detallada",
      salesByCashRegister: "Venta por caja",
      salesByClient: "Venta por cliente",
      financial: "Financiero",
      courtesies: "Cortesías",
      reprints: "Reimpresiones",
      refunds: "Reembolsos",
      balanceRefund: "Devolución de saldo",
      onlineRecharge: "Recargas en línea",
      receivables: "Cuentas por cobrar",
      cashOutAndSupply: "Retiro y suministro",
    },
    reportsTitle: {
      salesByProduct: "Informe de ventas por producto",
      salesByOperation: "Informe de ventas por operación",
      salesByPaymentMethod: "Informe de ventas por método de pago",
      salesByUser: "Informe de ventas por usuario",
      detailedSales: "Informe de ventas detallado",
      salesByCashRegister: "Informe de ventas por caja",
      salesByClient: "Informe de ventas por cliente",
      financial: "Informe financiero",
      courtesies: "Informe de cortesías",
      reprints: "Informe de reimpresiones",
      refunds: "Informe de reembolsos",
      balanceRefund: "Informe de devolución de saldo",
      onlineRecharge: "Informe de recargas en línea",
      receivables: "Informe de cuentas por cobrar",
      cashOutAndSupply: "Informe de retiro y suministro",
      listOfEquipmentByEvent: "Lista de equipos por evento",
    },
    products: "Productos",
    appointments: "Citas",
    categories: "Categorías",
    operations: "Operaciones",
    operationsList: "Lista de operaciones",
    users: "Usuarios",
    userList: "Lista de usuarios",
    deviceStatus: "Estado del dispositivo",
    digitalWallet: "Cartera Digital",
    elevenDash: "Panel de ElevenCash",
    statement: "Extracto",
    personalData: "Datos Personales",
    changePassword: "Cambiar Contraseña",
    balance: "Saldo",
    new: "NUEVO",
  },
  status: {
    active: "Activo",
    scheduled: "Programado",
    closed: "Cerrado",
    all: "Todo",
    total: "Total",
    selected: "Seleccionado",
    available: "Disponible",
  },
  filter: {
    title: "Filtros",
    rangeStart: "Inicio del rango",
    rangeEnd: "Fin del rango",
    applyFilter: "Aplicar filtros",
    document: "Documento",
    cardCode: "Código de tarjeta",
    customer: "Cliente",
    startDate: "Fecha de inicio",
    endDate: "Fecha de fin",
    user: "Usuario",
    users: "Usuarios",
    type: "Tipo",
    soldCourtesy: "Vendido/cortesía",
    equipment: "Equipo",
    operation: "Operación",
    operations: "Operaciones",
    paymentStatus: "Estado de pago",
    transactionStatus: "Estado de transacción",
    paymentMethods: "Métodos de pago",
    name: "Nombre",
    telephone: "Teléfono",
    email: "Correo electrónico",
    dateOfBirth: "Fecha de nacimiento",
    value: "Valor",
    acquirer: "Adquiridor",
  },
  form: {
    required: "Requerido",
    login: "Iniciar Sesión",
    signup: "Registrarse",
    cancel: "Cancelar",
    sendEmail: "Enviar Correo",
    create: "Crear",
    recoverPassword: "Recuperar Contraseña",
    forgotPassword: "Olvidé mi contraseña",
    newRegistration: "Nuevo Registro",
    newUser: "Nuevo Usuario",
    editUser: "Editar Usuario",
    newDevice: "Nuevo Dispositivo",
    editDevice: "Editar Dispositivo",
    import: "Importar",
    importDevices: "Importar Dispositivos",
    importCSV: "Importar CSV",
    selectCSVFile: "Seleccione un archivo CSV",
    downloadCSVmodel: "Descargar modelo CSV",
    rules: {
      required: "Campo obligatorio.",
      invalid: "Campo inválido",
      email: "Correo electrónico inválido.",
      upperLetters: "Utilice letras mayúsculas",
      lowerLetters: "Utilice letras minúsculas",
      numbers: "Utilice números",
      specialCharacters: "Utilice caracteres especiales",
      minimum: "Mínimo",
      maximum: "Máximo",
      characters: "caracteres",
      passwordsDontMatch: "Las contraseñas no coinciden",
      requiredDocument: "Documento obligatorio",
      invalidDocument: "Documento inválido",
      startRefundDateRequired: "Fecha de inicio del reembolso requerida",
      invalidRefundPeriod: "Período de reembolso inválido",
      endRefundDateRequired: "Fecha de fin del reembolso requerida"
    },
    info: {
      login: "Por favor, inicie sesión",
    },
    alerts: {
      user: {
        logoff: "¡El usuario cerró sesión exitosamente!",
        login: "¡El usuario inició sesión exitosamente!",
        signup: "¡El registro se completó con éxito!",
      },
      field: "Complete el campo obligatorio.",
      fields: "Complete los campos obligatorios.",
      recaptcha: "Complete el reCAPTCHA.",
      dataChanged: "Los datos cambiaron exitosamente!",
      passwordChanged: "La contraseña se cambió exitosamente!",
      expiredSession: "La sesión expiró, inicia sesión nuevamente para continuar!",
      categoryRegistered: "Categoría registrada exitosamente!",
      categoryUpdated: "Categoría actualizada exitosamente!",
      productRegistered: "Producto registrado con éxito!",
      productUpdated: "Producto actualizado con éxito!",
    },
    label: {
      user: "Usuario",
      password: "Contraseña",
      fullName: "Nombre Completo",
      phone: "Celular",
      telephone: "Teléfono",
      email: "Correo Electrónico",
      currentPassword: "Contraseña actual",
      newPassword: "Nueva contraseña",
      canRefund: "Permite reembolso",
      confirmNewPassword: "Confirmar nueva contraseña",
      serialNumber: "Número de serie",
      identifier: "Identificador",
      description: "Descripción",
      deviceDescription: "Descripción del dispositivo",
      deviceType: "Tipo de dispositivo",
      tefTerminal: "Terminal TEF",
      event: "Evento",
      eventName: "Nombre del evento",
      productName: "Nombre del producto",
      selectRepresentative: "Seleccionar representante",
      eventStartDate: "Fecha de inicio del evento",
      eventEndDate: "Fecha de finalización del evento",
      eventImage: "Imagen del evento",
      operationImage: "Imagen de la operación",
      operationInfo: "Información de operación",
      groupInfo: "Información del grupo",
      createOperationsWithPrefix: "Cree una o más operaciones con el mismo prefijo.",
      createAdminOperation: "Crear administrador y asistente predeterminados para todas las operaciones.",
      quantity: "Cantidad",
      viewOnly: "Solo visualización",
      canAccessReports: "Acceder a los informes de POS",
      printMerchantReceipt: "Imprimir recibo de pago",
      printOperatorReceipt: "Impresión del recibo del operador",
      printUserReceipt: "Impresión del recibo del cliente",
      canActivateCard: "Activar",
      canSellRecharge: "Recargar",
      canConsumeRecharge: "Venta Cashless",
      canSellOnlineRecharge: "Recarga en línea",
      canCashUpAndCashDown: "Puede ministrar saldo del registro",
      searchOnlineRechargeOnCardAcquistion: "Consulta de recarga en línea en la activación",
      searchOnlineRechargeOnSale: "Consulta de recarga en línea en la oferta",
      canSellGift: "Regalo",
      canRefundBalance: "Reembolso de saldo",
      canReplaceCard: "Reemplazar tarjeta",
      canTransferBalance: "Transferir saldo",
      canDisplayBalanceAndStatement: "Saldo y extracto",
      requiresDocument: "Solicitar documento",
      cashlessPrintType: "Tipo de impresión",
      cashlessPrintCopies: "Número de copias Cashless",
      requiresContactInfo: "Solicitar Información de Contacto",
      canSellTicket: "Vender Ficha",
      canConsumeTicket: "Quemar Ficha",
      canAutoBurnTicket: "Quema Automática de Fichas",
      canReturnTicket: "Devolver Ficha",
      ticketPrintType: "Tipo de Impresión",
      ticketCopyQuantity: "Cantidad de Copias",
      print: "Imprimir",
      doNotPrint: "No imprimir",
      toAsk: "Preguntar",
      profile: "Perfil",
      masterCard: "Tarjeta Maestra",
      masterPIN: "PIN maestro",
      integratorID: "ID del integrador",
      integratorIP: "Integrador IP",
      tokenIntegration: "Integración de token",
      category: "Categoría",
      categoryName: "Nombre de categoría",
      ageRating: "Clasificación por edad",
      displayOrder: "Orden de Visualización",
      icon: "Icono",
      insertImage: "Insertar Imagen",
      consumption: "Consumo",
      dynamicValue: "Valor Dinámico",
      useObservation: "Usar Observación",
      observationTitle: "Título de la Observación",
      taxIntegrationCode: "Código de Integración Fiscal",
      productType: "Tipo Producto",
      cardOrBandID: "ID de la Tarjeta o Pulsera",
      userSettings: "Configuraciones del Usuario",
      operationSettings: "Configuraciones de la Operación",
      eventSettings: "Configuración del Evento",
      eventUsers: "Usuarios del Evento",
      financialInformation: "Información Financiera",
      changeImage: "Cambiar Imagen",
      activationValue: "Valor de Activación",
      equipmentNumber: "Número de Equipos",
      occupiedLicenses: "Licencias Ocupadas",
      eventKey: "Clave del Evento",
      useEncryption: "Usar Cifrado",
      refundStartDate: "Fecha de Inicio del Reembolso",
      refundEndDate: "Fecha Final del Reembolso",
      onlineRefundFee: "Tarifa de Reembolso en Línea",
      customOnlineMenuURL: "URL Personalizada del Menú en Línea",
      eventRepresentative: "Representante del Evento",
      noRegisteredRepresentatives: "Sin Representantes Registrados",
      eventProducers: "Productores del Evento",
      noRegisteredProducers: "Sin Productores Registrados",
      techniciansList: "Lista de Técnicos",
      companyData: "Datos de la Empresa",
      individualPerson: "Persona Física",
      legalEntity: "Persona Jurídica",
      corporateName: "Razón Social",
      postalCode: "Código Postal",
      address: "Dirección",
      fees: "Tarifas",
      fee: "Tarifa",
      transferRule: "Regla de Transferencia",
      revenuePercentage: "Porcentaje de Ingresos",
      bankDetails: "Datos Bancarios",
      bank: "Banco",
      agency: "Agencia",
      account: "Cuenta",
      bankDetailsValidation: "Los datos bancarios deben coincidir con el nombre/razón social y el CPF/CNPJ registrados arriba",
      equipmentValue: "Valor del Equipo",
      posQuantity: "Cantidad de POS",
      posValue: "Valor por POS",
      totemQuantity: "Cantidad de Totems",
      totemValue: "Valor por Totem",
      totalEquipmentValue: "Valor Total de los Equipos",
      serviceValue: "Valor del Servicio",
      freights: "Fletes",
      technicianQuantity: "Cantidad de Técnicos",
      technicianValue: "Valor por Técnico",
      totalTechnicianValue: "Valor Total de Técnicos",
      cardBraceletValue: "Valor de Tarjetas/Pulseras",
      cardBraceletQuantity: "Cantidad de Tarjetas/Pulseras",
      cardBraceletUnitValue: "Valor por Tarjeta/Pulsera",
      totalCardBraceletValue: "Valor Total de Tarjetas/Pulseras",
      chargesValue: "Valor de los Cobros",
      totalValueToCharge: "Valor Total a Cobrar",
      eventFrequency: "¿Cuál es la frecuencia de tu evento?",
      singleDate: "Fecha Única",
      recurringEventOrBusiness: "Evento o Negocio Recurrente",
      useDigitalWallet: "¿Usa Billetera Digital?",
      paymentType: "Tipo de Pago",
      automaticPaymentToProducer: "Pago Automático al Productor",
      thirdPartyPayment: "Pago a Terceros",
      notes: "Notas",
      fiscalEnvironment: "Ambiente Fiscal",
      maximumDiscountPOS: "Valor máximo de descuento en el POS",
      maxiumRefundAmount: "Valor máximo de reembolso en el POS",
      serviceFee: "Tarifa de Servicio",
      defaultPassword: "Contraseña Predeterminada",
      withAccess: "Con acceso al portal",
      withoutAccess: "Sin acceso al portal",
      operationUsers: "Usuarios de la operación",
      presets: "Predefiniciones",
      recurrenceType: "Tipo de Recurrencia",
      endTime: "Hora de finalización",
      startTime: "Hora de inicio",
      appointmentHours: "Horario de cita",
      noSetTime: "Sin horario",
    },
    tooltip: {
      readOnly: "Define si el usuario solo puede visualizar, sin poder cambiar valores",
      canAccessReports: "Define si el usuario puede acceder a los informes de POS",
      printMerchantReceipt: "Define si el usuario puede imprimir la copia del comerciante",
      printOperatorReceipt: "Define el comportamiento de la impresión del recibo del operador al completar una transacción",
      printUserReceipt: "Define el comportamiento de la impresión del recibo del cliente al completar una transacción<br>" +
        "<ul>" +
        "<li>Los siguientes recibos se verán afectados:</li>" +
        "<li>Recarga cashless</li>" +
        "<li>Recarga en línea cashless</li>" +
        "<li>Reembolso de saldo y recarga</li>" +
        "<li>Transferencia de saldo</li>" +
        "<li>Reversión de venta de tokens y cashless</li>" +
        "<li>Reembolso de tokens</li>" +
        "</ul>",
      canActivateCard: "Define si el usuario puede activar tarjetas en el POS",
      canSellRecharge: "Define si el usuario puede recargar tarjetas",
      canConsumeRecharge: "Define si el usuario puede vender productos cashless",
      canSellOnlineRecharge: "Define si el usuario puede realizar recargas en línea",
      canCashUpAndCashDown: "Determina si el usuario puede o no puede administrar el saldo de registro",
      searchOnlineRechargeOnCardAcquistion: "Compruebe si el código de la tarjeta o el documento ingresado tiene recargas en línea vinculadas en el momento de la activación",
      searchOnlineRechargeOnSale: "Compruebe si el código o documento de la tarjeta tiene recargas en línea vinculadas cuando la tarjeta está agotada",
      canRefundBalance: "Define si el usuario puede procesar reembolsos y devoluciones de saldo o tarjetas",
      canReplaceCard: "Define si el usuario puede reemplazar una tarjeta por otra",
      canTransferBalance: "Define si el usuario puede transferir saldo de una tarjeta a otra",
      canDisplayBalanceAndStatement: "Define si el usuario puede visualizar el saldo y extracto de la tarjeta",
      requiresDocument: "Define si se requiere un documento para activar la tarjeta",
      cashlessPrintType: "Define el tipo de impresión para Cashless.",
      cashlessPrintCopies: "Define el número de copias para impresión Cashless.",
      requiresContactInfo: "Define si se solicita la información de contacto para activar la tarjeta",
      canSellTicket: "Define si el usuario puede vender tickets.",
      canConsumeTicket: "Define si el usuario puede validar/quemar tickets",
      canAutoBurnTicket: "Define si el usuario puede quemar tokens automáticamente",
      canReturnTicket: "Define si el usuario puede devolver un boleto",
      ticketPrintType: "Define el tipo de impresión del ticket",
      ticketCopyQuantity: "Seleccione la cantidad de impresiones de boletos",
      exportPDF: "Exportar PDF",
      exportCSV: "Exportar CSV",
      exportBordereau: "Exportar borderó del evento",
      importCSV: `<small><span>Aquí tienes algunos consejos para que puedas importar tus productos sin problemas.</span><ul><li>Define como separador la opción punto y coma ";" en la hoja de cálculo.</li><li>Verifica que el nombre del producto, la categoría o el valor sean correctos.</li><li>Los valores de los productos deben estar en centavos, solo números, sin caracteres como puntos o comas. Ejemplo: R$1,00 > 100</li></ul></small>`,
    },
    placeholder: {
      user: "Ingrese el usuario",
      password: "Ingrese la contraseña",
      document: "Ingrese el documento",
      fullName: "Ingrese el nombre",
      phone: "Ingrese el celular",
      email: "Ingrese el correo electrónico",
      eventSearch: "Buscar por nombre de evento",
      proofOfTransfer: "Ingrese el comprobante de transferencia",
      general: "General",
      ticket: "Ticket",
      productName: "Ingrese el nombre del producto",
      categoryName: "Ingrese el nombre de la categoría",
      operationName: "Ingrese el nombre de la operación",
      selectCategory: "Seleccione la categoría",
      maximumSize: "Tamaño máximo 2MB",
      productValue: "Ingrese el valor del producto",
      productConsumption: "Ingrese el consumo del producto",
      notes: "Ingrese aquí notas del evento, cobros extras, pérdida de equipo, etc.",
    },
    options: {
      specificDate: "Fecha Específica" ,
      recurring: "Recurrente",
      activePeriod: "Período Activo",
      inactivePeriod: "Período Inactivo",
      entry: "Entrada",
      exit: "Salida",
    }
  },
  alt: {
    thinkingPerson: "Imagen de una persona pensando.",
  },
  cardTitle: {
    totalSold: "Total Vendido",
    totalConsumed: "Total Consumido",
    toBeConsumed: "Falta Consumir",
    ranking: {
      products: "Ranking de Productos",
      clients: "Ranking de Clientes",
      operations: "Ranking de Operaciones",
    },
    paymentMethod: "Formas de Pago",
    cardsAndBands: "Tarjetas/Pulseras",
    devices: "Equipos",
    otherTransactions: "Otras Transacciones",
    consumptionPerHour: "Consumo Por Hora",
    details: "Detalles",
    updateRegisteredData: "Actualizar datos registrados",
  },
  header: {
    from: "Desde",
    until: "Hasta",
    to: "al",
    seconds: "segundos",
    date: "Fecha",
    hour: "Hora",
    updating: "Actualizando...",
    updatingIn: "Actualizando en",
    updatingData: "Actualizando datos en",
    noSetDate: "Sin fecha definida",
    noLinkedEvent: "El usuario no tiene un evento vinculado.",
    noLinkedCard: "El usuario no tiene una tarjeta vinculada.",
    noEventFound: "No se encontraron eventos.",
    noResultFound: "No se encontraron resultados.",
    resultsPerPage: "Resultados por página",
    noHistory: "No hay historial para mostrar",
  },
  ageClassification: {
    free: "Libre de restricción",
    prohibitedUnder: "Prohibido para menores de {age} años",
  },
  icons: {
    drinks: "Bebidas",
    food: "Comida",
    souvenirs: "Recuerdos",
    car: "Coche (Estacionamiento)",
    cocktail: "Cocteles",
    others: "Otros",
  },
  weekDays: {
    title: "Días de la semana",
    everyDay: "Todos los días",
    sunday: "Domingo",
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "Sábado",
  },
  actions: {
    actions: "Acciones",
    yes: "Sí",
    no: "No",
    optional: "Opcional",
    notice: "Aviso",
    search: "Buscar",
    apply: "Aplicar",
    save: "Guardar",
    confirm: "Confirmar",
    register: "Registrar",
    saveRegistration: "Guardar Registro",
    clear: "Limpiar",
    next: "Siguiente",
    edit: "Editar",
    delete: "Eliminar",
    remove: "Eliminar",
    duplicate: "Duplicar",
    checkAll: "Verificar todo",
    uncheckAll: "Desmarcar todo",
    addAll: "Agregar todo",
    removeAll: "Eliminar todo",
    editEvent: "Editar evento",
    duplicateEvent: "Duplicar evento",
    deleteEvent: "Eliminar evento",
    newGroup: "Nuevo Grupo",
    newProduct: "Nuevo Producto",
    newAppointment: "Nueva Cita",
    editProduct: "Editar Producto",
    importSpreadsheet: "Importar Hoja de Cálculo",
    onlineMenu: "Menú Online",
    copyLink: "Copiar Enlace",
    download: "Descargar",
    newCategory: "Nueva Categoría",
    newOperation: "Nueva Operación",
    createOperations: "Crear Operaciones",
    configureOperations: "Configurar Operaciones",
    addOperations: "Agregar Operaciones",
    editOperations: "Editar Operaciones",
    addUsers: "Agregar Usuarios",
    addUsersInBulk: "Agregar Usuarios en Masa",
    addUsersInBulkDescription: "Cree múltiples usuarios con la misma configuración para todas las operaciones a la vez.",
    noRegisteredOperations: "Sin operaciones registradas",
    editCategory: "Editar Categoría",
    notDefined: "Acción no definida",
    deleteEventConfirmation: "¿Desea eliminar el evento {event}?",
    requestRefund: "Solicitar reembolso",
    refundRequest: "Solicitud de reembolso",
    refundDeadline: "El plazo para solicitar la devolución es del",
    balanceToBeRefunded: "Saldo a reembolsar",
    refundFee: "Tarifa de reembolso",
    deactivateCategory: "Inactivar Categoría",
    categoryDeactivationConfirmation: "La inactivación de la categoría <strong>{category}</strong> puede afectar la disponibilidad de varios productos. Presione CONFIRMAR para confirmar la acción.",
    pixTransfer: "Se realizará una transferencia PIX al documento vinculado a su registro.<br><br>La clave PIX CPF es la mejor manera de garantizar que el solicitante será el beneficiario del reembolso.<u>Asegúrese de que su CPF sea registrado y capaz de recibir transacciones de este tipo.</u><br><br>Si no desea conservarla, la clave se puede desactivar una vez confirmado el reembolso.",
    cpfNotice: "El registro debe realizarse con el CPF del consumidor que activó la pulsera o tarjeta.",
    selectCard: "Seleccione una tarjeta",
    confirmTransferRequest: "¿Confirmar la solicitud de transferencia por valor de",
    finalizeRequest: "Finalizar solicitud",
    processingRequest: "Procesando solicitud",
    directedToRanking: "Haga clic aquí para ser dirigido al informe sobre este ranking.",
    searchForInformation: "Buscar información",
    searchForWithdrawals: "Buscar retiros",
    requestWithdrawal: "Solicitar retiro",
    pay: "Pagar",
    payThirdParties: "Pagar a terceros",
    normal: "Normal",
    gift: "Regalo",
    refundSuccess: "Solicitud de reembolso realizada con éxito",
    ok: "Ok"  
  },
  digitalWallet: {
    availableBalance: "Saldo disponible",
    heldBalance: "Saldo retenido",
    waitingForRelease: "Esperando liberación",
    transferredAmount: "Monto transferido",
    totalRevenue: "Ingresos totales",
    elevenInvoices: "Facturas Eleven",
    acquirerFees: "Tasas del adquirente",
    waitingForAcquirerRelease: "Esperando la liberación del monto por el adquirente.",
    amountTransferredToProducer: "Monto ya transferido al productor.",
    netRevenueAfterFees: "Ingresos netos después de las tasas del adquirente.",
    availableBalanceForWithdrawal: "Saldo disponible para retiro",
    withdrawalProcessingInfo: "Todas las transferencias solicitadas (retiro o pago a terceros) serán procesadas en 48 horas hábiles.",
    withdrawalAmount: "Monto del retiro",
    description: "Descripción",
    payThirdPartiesInfo: "Realice pagos a proveedores y cuentas de terceros utilizando su saldo disponible.",
    paymentAmount: "Monto del pago",
    accountDataForPayment: "Datos de la cuenta para el pago",
    recipient: "Destinatario",
    insertRecipient: "Ingrese el destinatario",
    checkingAccount: "Cuenta corriente",
    savingsAccount: "Cuenta de ahorros",
    createPaymentIdentifier: "Cree un identificador de pago.",
    insertTransferReceipt: "Ingrese el comprobante de transferencia",
    emailCodeValidation: "Validación de código por correo electrónico",
    enterCodeToFinish: "Ingrese el código enviado al correo electrónico registrado para completar la operación",
    tokenCode: "Código Token",
    insertCodeSentByEmail: "Ingrese el código enviado por correo electrónico",
    cancelTransferRequest: "¿Desea <span>cancelar</span> la solicitud de transferencia por el monto de <span>R$ {value}</span>?",
    rejectTransferRequest: "¿Desea <span>rechazar</span> la solicitud de transferencia por el monto de <span>R$ {value}</span>?",
    rejectionReason: "Motivo de rechazo",
    informRejectionReason: "Proporcione el motivo del rechazo.",
    cancellationReason: "Motivo de cancelación",
    informCancellationReason: "Proporcione el motivo de la cancelación.",
    giveUp: "Desistir",
    reject: "Rechazar",
    noDataToView: "Sin datos para visualizar.",
    noDataFound: "No se encontraron datos.",
    noInfoToGenerateFile: "Sin información para generar el archivo",
    searchingWithdrawals: "Buscando retiros.",
  },
  schedule: {
    creation: {
      success: "Cita creada con éxito",
      failure: "Error al crear cita",
    },
    editing: {
      success: "Cita editada con éxito",
      failure: "Error al editar cita",
    },
    deletion: {
      success: "Cita eliminada con éxito",
      failure: "Error al eliminar cita",
    },
    list: {
      failure: "Error al listar citas",
    },
  },
  register: {
    open: {
      fail: "Error al abrir registro",
    },
    value: {
      fail: "Error al registrar valor",
    },
  },
  cards: {
    reason: "Razón",
    blockCard: "Bloquear tarjeta",
    selectReason: "Seleccionar motivo de bloqueo",
    cardHistory: "Historial de la tarjeta",
    inUse: {
      failure: "Error al listar tarjetas en uso",
    },
    balance: {
      failure: "Error al consultar el saldo de la tarjeta",
    },
    search: {
      error: "Error al buscar tarjeta",
    },
    block: {
      block: "Bloquear",
      success: "Tarjeta bloqueada con éxito",
      failure: "Error al bloquear tarjeta",
    },
  },
  wallet: {
    values: {
      failure: "Error al consultar valores de la cartera",
    },
  },
  category: {
    search: {
      failure: "Error al buscar categorías",
    },
    creation: {
      success: "Categoría creada con éxito",
      failure: "Error al crear categoría",
    },
    editing: {
      success: "Categoría editada con éxito",
      failure: "Error al editar categoría",
    },
    deletion: {
      success: "Categoría eliminada con éxito",
      failure: "Error al eliminar categoría",
    },
    restoration: {
      success: "Categoría restaurada con éxito",
      failure: "Error al restaurar categoría",
    },
  },
  config: {
    editing: {
      failure: "Error al editar configuraciones",
    },
    get: {
      failure: "Error al obtener configuraciones",
    },
  },
  bankingAccount: {
    listActive: {
      failure: "Error al listar cuentas activas",
    },
    creation: {
      failure: "Error al crear cuenta bancaria",
    },
    listByPerson: {
      failure: "Error al listar cuentas por persona",
    },
    changeAccountStatus: {
      failure: "Error al cambiar estado de la cuenta",
    },
    update: {
      failure: "Error al actualizar cuenta bancaria",
    },
  },
  equipment: {
    list: {
      failure: "Error al listar equipos",
    },
    status: {
      failure: "Error al consultar estado del equipo",
    },
    creation: {
      success: "Equipo creado con éxito",
      failure: "Error al crear equipo",
    },
    listByEvent: {
      failure: "Error al listar equipos por evento",
    },
    listAcquirers: {
      failure: "Error al listar adquirentes de equipos",
    },
  },
  event: {
    refund: {
      notAllowed: "No se permiten reembolsos para este evento"
    },
    massEquipmentImport: {
      success: "Importación masiva de equipos realizada con éxito",
      failure: "Error en la importación masiva de equipos",
    },
    list: {
      failure: "Error al listar eventos",
    },
    listById: {
      failure: "Error al listar evento por ID",
    },
    listByOperatorId: {
      failure: "Error al listar eventos por operador",
    },
    dates: {
      failure: "Error al consultar fechas del evento",
    },
    editing: {
      success: "Evento editado con éxito",
      failure: "Error al editar evento",
    },
    creation: {
      success: "Evento creado con éxito",
      failure: "Error al crear evento",
    },
    deletion: {
      success: "Evento eliminado con éxito",
      failure: "Error al eliminar evento",
    },
    duplication: {
      success: "Evento duplicado con éxito",
      failure: "Error al duplicar evento",
    },
    licenses: {
      failure: "Error al consultar licencias del evento",
    },
    menu: {
      failure: "Error al cargar el menú del evento",
    },
  },
  paymentMethod: {
    list: {
      failure: "Error al listar métodos de pago",
    },
  },
  gateway: {
    list: {
      failure: "Error al listar pasarelas",
    },
  },
  management: {
    dailyRevenue: {
      failure: "Error al consultar ingresos diarios",
    },
    topEvents: {
      failure: "Error al consultar los principales eventos",
    },
    cardsAndBracelets: {
      failure: "Error al consultar tarjetas y pulseras",
    },
    paymentMethodRank: {
      failure: "Error al consultar el ranking de métodos de pago",
    },
    totalTransacted: {
      failure: "Error al consultar el total transaccionado",
    },
    revenue: {
      failure: "Error al consultar ingresos",
    },
  },
  graph: {
    hourConsumption: {
      failure: "Error al consultar consumo por hora",
    },
  },
  userGroup: {
    creation: {
      failure: "Error al crear grupo de usuarios",
      success: "Grupo de usuarios creado con éxito"
    },
    deletion: {
      success: "Grupo de usuarios eliminado con éxito",
      failure: "Error al eliminar grupo de usuarios",
    },
    restoration: {
      success: "Grupo de usuarios restaurado con éxito",
      failure: "Error al restaurar grupo de usuarios",
    },
    editing: {
      success: "Grupo de usuarios editado con éxito",
      failure: "Error al editar grupo de usuarios",
    },
    list: {
      failure: "Error al listar grupos de usuarios",
    },
    link: {
      success: "Usuario vinculado al grupo con éxito",
      failure: "Error al vincular usuario al grupo",
    },
    unlink: {
      success: "Usuario desvinculado del grupo con éxito",
      failure: "Error al desvincular usuario del grupo",
    },
  },
  indicators: {
    grossAmount: {
      failure: "Error al consultar valor bruto",
    },
    grossAmountDetails: {
      failure: "Error al consultar los detalles del valor bruto",
    },
    totalConsumed: {
      failure: "Error al consultar el total consumido",
    },
    lackConsumption: {
      failure: "Error al consultar la ausencia de consumo",
    },
    lackConsumptionDetails: {
      failure: "Error al consultar los detalles de ausencia de consumo",
    },
  },
  fakeEvent: {
    verifyDomainAvailability: {
      failure: "Error al verificar la disponibilidad del dominio",
    },
    creation: {
      failure: "Error al crear evento falso",
    },
    listProducers: {
      failure: "Error al listar productores",
    },
    collectProducer: {
      failure: "Error al recopilar datos del productor",
    },
    listAllEvents: {
      failure: "Error al listar todos los eventos",
    },
    listEventsProducer: {
      failure: "Error al listar eventos del productor",
    },
    deletion: {
      failure: "Error al eliminar evento falso",
    },
  },
  operations: {
    list: {
      failure: "Error al listar operaciones",
    },
    get: {
      failure: "Error al consultar operación",
    },
    editing: {
      success: "Operación editada con éxito",
      failure: "Error al editar operación",
    },
    deletion: {
      success: "Operación eliminada con éxito",
      failure: "Error al eliminar operación",
    },
    massCreation: {
      success: "Creación masiva de operaciones realizada con éxito",
      failure: "Error en la creación masiva de operaciones",
    },
    duplication: {
      success: "Operación duplicada con éxito",
      failure: "Error al duplicar operación",
    },
  },
  profile: {
    list: {
      failure: "Error al listar perfiles",
    },
  },
  person: {
    login: {
      notFound: "Usuario no encontrado. Por favor regístrese para continuar.",
      invalidCredentials: "CPF o contraseña no válida. Por favor inténtalo de nuevo.",
      failure: "Error al iniciar sesión",
    },
    personEnv: {
      failure: "Error al consultar el entorno de la persona",
    },
    creation: {
      failure: "Error al crear persona",
    },
    get: {
      failure: "Error al consultar persona",
    },
    update: {
      failure: "Error al actualizar persona",
    },
    changePassword: {
      success: "Contraseña cambiada con éxito",
      failure: "Error al cambiar contraseña",
    },
    recoverPassword: {
      success: "Correo electrónico para recuperación de acceso enviado a la dirección vinculada al documento ",
      failure: "Error al recuperar acceso",
    },
  },
  product: {
    list: {
      failure: "Error al listar productos",
    },
    creation: {
      deletion: "Producto creado y eliminado",
      failure: "Error al crear producto",
    },
    massCreation: {
      failure: {
        invalidContent: "Error en la creación masiva: contenido inválido",
      },
      success: "Creación masiva de productos realizada con éxito",
      failure: "Error en la creación masiva de productos",
    },
    editing: {
      success: "Producto editado con éxito",
    },
    deletion: {
      success: "Producto eliminado con éxito",
      failure: "Error al eliminar producto",
    },
    restoration: {
      success: "Producto restaurado con éxito",
      failure: "Error al restaurar producto",
    },
    getById: {
      failure: "Error al buscar producto por ID",
    },
    ocult: {
      success: "Producto ocultado con éxito",
    },
    menu: {
      success: "Menú de productos cargado con éxito",
    },
  },
  withdraw: {
    creationProducer: {
      success: "Retiro creado para productor con éxito",
    },
    creationThirdParty: {
      success: "Retiro creado para terceros con éxito",
      failure: "Error al crear retiro para terceros",
    },
    auth: {
      success: "Autorización de retiro realizada con éxito",
      failure: "Error en la autorización de retiro",
    },
    cancel: {
      success: "Retiro cancelado con éxito",
      failure: "Error al cancelar retiro",
    },
    approve: {
      success: "Retiro aprobado con éxito",
      failure: "Error al aprobar retiro",
    },
    reject: {
      success: "Retiro rechazado con éxito",
      failure: "Error al rechazar retiro",
    },
    editing: {
      success: "Retiro editado con éxito",
      failure: "Error al editar retiro",
    },
  },
  sync: {
    transactions: {
      failure: "Error al sincronizar transacciones",
    },
    mov: {
      failure: "Error al sincronizar movimientos",
    },
    cards: {
      failure: "Error al sincronizar tarjetas",
    },
    tokens: {
      failure: "Error al sincronizar tokens",
    },
    person: {
      failure: "Error al sincronizar datos de la persona",
    },
  },
  user: {
    login: {
      notFound: "Usuario no encontrado. Por favor regístrese para continuar.",
      invalidCredentials: "CPF o contraseña no válida. Por favor inténtalo de nuevo.",
      failure: "Error al iniciar sesión de usuario",
    },
    list: {
      failure: "Error al listar usuarios",
    },
    getById: {
      failure: "Error al buscar usuario por ID",
    },
    creation: {
      success: "Usuario creado con éxito",
      failure: "Error al crear usuario",
    },
    deletion: {
      success: "Usuario eliminado con éxito",
      failure: "Error al eliminar usuario",
    },
    editing: {
      success: "Usuario editado con éxito",
      failure: "Error al editar usuario",
    },
    restoration: {
      success: "Usuario restaurado con éxito",
      failure: "Error al restaurar usuario",
    },
    changePassword: {
      success: "Contraseña del usuario cambiada con éxito",
      failure: "Error al cambiar la contraseña del usuario",
    },
  },
  categories: {
    search: {
      id: "ID",
      category: "Categoría",
      icon: "Icono",
      order: "Orden",
      ageClassification: "Clasificación por Edad",
      active: "Activo"
    }
  },
  cardList: {
    statement: {
      code: "Código",
      document: "Documento",
      client: "Cliente",
      balance: "Saldo",
      status: "Estado",
    }
  },
  group: {
    search: {
      id: "ID",
      description: "Descripción",
    }
  },
  report: {
    productSales: {
      category: "Categoría",
      product: "Producto",
      sold: "Vendido",
      refunded: "Devuelto",
      total: "Total",
      unitValue: "Valor Unitario",
      totalValue: "Valor Total",
    },
    operationSales: {
      operation: "Operación",
      category: "Categoría",
      product: "Producto",
      sold: "Vendido",
      refunded: "Devuelto",
      total: "Total",
      unitValue: "Valor Unitario",
      totalValue: "Valor Total",
    },
    paymentMethodSales: {
      equipment: "Equipo",
      cash: "Efectivo",
      debit: "Débito",
      credit: "Crédito",
      pix: "Pix",
      banricompras: "Banricompras",
      courtesyAndRechage: "Recarga - Cortesía",
      activation: "Activación",
      activationCourtesy: "Activación - Cortesía",
      other: "Otras Formas",
      value: "Valor",
      consumption: "Consumido",
      courtesyConsumption: "Consumo - Cortesía",
    },
    userSales: {
      operator: "Operador",
      cash: "Efectivo",
      debit: "Débito",
      credit: "Crédito",
      pix: "Pix",
      banricompras: "Banricompras",
      activation: "Activación",
      discount: "Descuentos",
      serviceFee: "Tarifa de Servicio",
      other: "Otras Formas",
      value: "Valor",
      consumption: "Consumo"
    },
    detailed: {
      movId: "ID de Movimiento",
      registerId: "ID de Caja",
      creationDate: "Fecha de Creación",
      lastUpdated: "Última Actualización",
      type: "Tipo",
      equipment: "Equipo",
      operation: "Operación",
      operator: "Operador",
      amountPaid: "Monto Pagado",
      amountConsumed: "Monto Consumido",
      paymentStatement: "Extracto de Pago",
      transactionStatement: "Extracto de Transacción",
    },
    detailedPayments: {
      movId: "ID de Movimiento",
      paymentId: "ID de Pago",
      registerId: "ID de Caja",
      creationDate: "Fecha de Creación",
      lastUpdate: "Última Actualización",
      equipment: "Equipo",
      operation: "Operación",
      operator: "Operador",
      type: "Tipo",
      paymentMethod: "Método de Pago",
      value: "Valor",
      total: "Total",
      paymentStatus: "Estado del Pago",
      card: "Tarjeta",
      document: "Documento",
      balanceInCard: "Saldo en Tarjeta",
      cardSequential: "Secuencia de Tarjeta",
      pdvSequential: "Secuencia de PDV",
      supervisor: "Supervisor",
      details: "Detalles",
      nsu: "NSU",
      flag: "Bandera",
      tefReturn: "Comprobante"
    },
    detailedTransactions: {
      movId: "ID de Movimiento",
      transactionId: "ID de Transacción",
      registerId: "ID de Caja",
      creationDate: "Fecha de Creación",
      lastUpdate: "Última Actualización",
      equipment: "Equipo",
      operation: "Operación",
      operator: "Operador",
      type: "Tipo",
      value: "Valor",
      status: "Estado",
      product: "Producto",
      productCategory: "Categoría del Producto",
      supervisor: "Supervisor",
      card: "Tarjeta",
      document: "Documento",
      balanceInCard: "Saldo en Tarjeta",
    },
    register: {
      operation: "Operación",
      user: "Usuario",
      equipment: "Equipo",
      opening: "Apertura",
      closing: "Cierre",
      cash: "Efectivo",
      debit: "Débito",
      credit: "Crédito",
      pix: "Pix",
      onlineRecharge: "Recarga en Línea",
      rechargeTotal: "Total Recargas",
      totalTokenSold: "Total Vendido en Fichas",
      totalConsumed: "Total Consumido",
      supplyRegister: "Abastecimiento de Caja",
      cashFloat: "Sangría de Caja",
      inHandsCash: "Efectivo en Mano",
      tokenRefund: "Devolución de Fichas",
      balanceRefund: "Devolución de Saldo",
      courtesyRecharge: "Recarga Cortesía",
      courtesyConsumption: "Consumo Cortesía",
      bonus: "Bonificación",
      advanced: "Adelantado",
      voucher: "Vale",
      other: "Otros",
      registerId: "ID de Caja"
    },
    clients: {
      name: "Nombre",
      document: "Documento",
      telephone: "Teléfono",
      email: "Correo Electrónico",
      dateOfBirth: "Fecha de Nacimiento",
      maxValueSpent: "Valor Máximo Gastado"
    },
    finance: {
      equipment: "Equipo",
      cash: "Efectivo",
      debit: "Débito",
      credit: "Crédito",
      totalCredit: "Crédito Total",
      pix: "Pix",
      onlineRecharge: "Recarga en Línea",
      totalSold: "Total Vendido",
      totalTokenSold: "Total Vendido en Fichas",
      totalConsumed: "Total Consumido",
      registerSupply: "Abastecimiento de Caja",
      cashFloat: "Sangría de Caja",
      inHandsCash: "Efectivo en Mano",
      tokenRefund: "Devolución de Fichas",
      balanceRefund: "Devolución de Saldo",
      courtesyRecharge: "Recarga Cortesía",
      courtesyConsumption: "Consumo Cortesía",
      bonus: "Bonificación",
      advanced: "Adelantado",
      voucher: "Vale",
      other: "Otros"
    },
    courtesy: {
      equipment: "Equipo",
      operation: "Operación",
      user: "Usuario",
      supervisor: "Supervisor",
      dateTime: "Fecha/Hora",
      card: "Tarjeta",
      name: "Nombre",
      document: "Documento",
      type: "Tipo",
      product: "Producto",
      value: "Valor"
    },
    reprinting: {
      supervisor: "Supervisor",
      equipment: "Equipo",
      operation: "Operación",
      user: "Usuario",
      dateTime: "Fecha/Hora",
      card: "Tarjeta",
      document: "Documento",
      type: "Tipo",
      status: "Estado",
      product: "Producto",
      value: "Valor"
    },
    productRefund: {
      equipment: "Equipo",
      operation: "Operación",
      operator: "Operador",
      dateTime: "Fecha/Hora",
      cardId: "ID de Tarjeta",
      cpf: "CPF",
      type: "Tipo",
      product: "Producto",
      value: "Valor"
    },
    balanceRefund: {
      equipment: "Equipo",
      operation: "Operación",
      operator: "Operador",
      product: "Producto",
      dateTime: "Fecha/Hora",
      cardId: "ID de Tarjeta",
      cpf: "CPF",
      type: "Tipo",
      refundedValue: "Valor Devuelto"
    },
    cardOnlineRecharge: {
      dateTime: "Fecha y Hora",
      document: "Documento",
      card: "Tarjeta",
      name: "Nombre",
      value: "Valor",
      status: "Estado"
    },
    receivables: {
      operator: "Operador",
      credit: "Crédito",
      liquidCredit: "Crédito Neto",
      debit: "Débito",
      liquidDebit: "Débito Neto",
      pix: "Pix",
      liquidPix: "Pix Neto",
      total: "Total",
      liquidTotal: "Total Neto"
    },
    cashFloatAndRegisterSupply: {
      dateTime: "Fecha y Hora",
      supervisor: "Supervisor",
      equipment: "Equipo",
      operator: "Operador",
      paymentType: "Tipo de Pago",
      value: "Valor"
    },
    products: {
      id: "ID",
      categoryId: "ID de Categoría",
      image: "Imagen",
      product: "Producto",
      category: "Categoría",
      value: "Valor",
      type: "Tipo",
      active: "Activo",
      qrCode: "Código QR",
      useObservation: "Usa Observaciones",
      observationTitle: "Título de Observaciones",
      enabled: "Habilitado"
    },
    operations: {
      id: "ID",
      fantasy: "Fantasía",
      reason: "Razón"
    },
    equipmentByEvent: {
      equipment: "Equipo",
      equipmentId: "ID del Equipo",
      event: "Evento",
      initialDate: "Fecha Inicial",
      finalDate: "Fecha Final",
      acquirer: "Adquirente",
      eventId: "ID del Evento"
    },
    withdrawals: {
      identifier: "Identificador",
      updateDate: "Fecha de Actualización",
      applicant: "Solicitante",
      status: "Estado",
      approval: "Aprobación",
      withdrawalValue: "Valor del Retiro",
      withdrawalId: "ID del Retiro",
      document: "Documento",
      approvalDate: "Fecha de Aprobación",
      receipt: "Recibo",
      justification: "Justificación",
      observation: "Observación"
    },
    users: {
      id: "ID",
      name: "Nombre",
      user: "Usuario",
      profile: "Perfil",
      operation: "Operación",
      creation: "Creación",
      active: "Activo"
    },
    equipmentStatus: {
      user: "Usuario",
      equipment: "Equipo",
      batteryLevel: "Nivel de Batería",
      charging: "Cargando",
      registerOpen: "Caja Abierta",
      appVersion: "Versión de la Aplicación",
      networkType: "Tipo de Red",
      signalStrength: "Intensidad de Señal",
      ssid: "SSID",
      ip: "IP",
      transactionCount: "Cantidad de Transacciones",
      notSyncedTransactionCount: "Transacciones no Sincronizadas",
      lastCommunication: "Última Comunicación",
      uptime: "Tiempo Activo",
      latency: "Latencia",
      registerBalance: "Saldo de Caja",
      latitude: "Latitud",
      longitude: "Longitud",
      applications: "Aplicaciones",
      acquirer: "Adquirente"
    },
    attendant: {
      attendant: "Atendiente",
      totalValue: "Valor Total",
      percentage: "Porcentaje"
    },
    cardStatement: {
      card: "Tarjeta",
      document: "Documento",
      date: "Fecha",
      item: "Artículo",
      value: "Valor",
      balance: "Saldo",
      paymentMethod: "Forma de Pago",
      operator: "Operador",
      operation: "Operación",
      paymentStatus: "Estado de Pago",
      transactionStatus: "Estado de Transacción",
      effect: "Efecto",
      equipment: "Equipo",
    },
  },
  enum: {
    blockReason: {
      negativeBalance: "Saldo negativo",
      maxWebRecharge: "Valor de recarga superior al máximo permitido para Web",
      minWebRecharge: "Valor de recarga inferior al mínimo permitido para Web",
      maxPDVRecharge: "Valor de recarga superior al máximo permitido para PDV",
      minPDVRecharge: "Valor de recarga inferior al mínimo permitido para PDV",
      maxTCIRecharge: "Valor de recarga superior al máximo permitido para TCI",
      minTCIRecharge: "Valor de recarga inferior al mínimo requerido para TCI",
      overLimitBalance: "Saldo superior al límite permitido",
      documentMissing: "Tarjeta sin documento vinculado",
      lose: "Pérdida",
      theft: "Robo",
      equipmentMissing: "Tarjeta sin equipo o con equipo inválido vinculado",
      webRefund: "Reembolso",
      balanceTransference: "Transferencia de saldo",
      cardReuse: "Reutilización de Tarjeta (Desbloqueo)",
      damagedCard: "Tarjeta Dañada",
    },
    cardStatus: {
      freeCard: "Libre",
      active: "Activo",
      blocked: "Bloqueado",
      blockedBlacklist: "Bloqueado - Lista Negra",
      blockedCrc: "Bloqueado - Comprobación de Redundancia Cíclica",
      blockedIncorrectPassword: "Bloqueado - Contraseña Incorrecta",
      blockedCardReturned: "Bloqueado - Tarjeta fue devuelta",
      dateOfBirthChanged: "Fecha de nacimiento fue cambiada",
      passwordChanged: "Contraseña fue cambiada",
      restrictionChanged: "Restricción",
    },
    cardType: {
      standard: "Tarjeta estándar",
      courtesy: "Tarjeta de cortesía",
    },
    pixKeyType: {
      email: "Correo electrónico",
      cpf: "CPF",
      cnpj: "CNPJ",
      cellphone: "Teléfono",
      random: "Clave aleatoria",
    },
    bankAccountType: {
      currentIndividual: "Cuenta Corriente Individual",
      currentBusiness: "Cuenta Corriente Empresarial",
      savingsIndividual: "Cuenta de Ahorros Individual",
      savingsBusiness: "Cuenta de Ahorros Empresarial",
    },
    acquirer: {
      pagseguro: "PagSeguro",
      vero: "Vero",
      softwareexpress: "SoftwareExpress",
      auttar: "Auttar",
      unknown: "No informado",
    },
    equipmentType: {
      server: "SERVIDOR",
      parkingMeter: "PARQUÍMETRO",
      turnstile: "TORNIQUETE",
      barrier: "BARRERA",
      tps: "TPS",
      parkingSensor: "SENSOR DE ESTACIONAMIENTO",
      tci: "TÓTEM",
      pdv: "smartPOS",
      supermedia: "SUPERMEDIA",
      maestroTotemViewer: "MAESTRO TÓTEM / MAESTRO VISUALIZADOR",
      maestroEvaluator: "MAESTRO EVALUADOR",
      maestroMobileCaller: "MAESTRO LLAMADOR MÓVIL",
      multiKiosk: "MULTI QUIOSCO",
      weatherStation: "ESTACIÓN METEOROLÓGICA",
      bowlingLane: "PISTA DE BOLICHE",
      bowlingPinIndicator: "INDICADOR DE PINS DE BOLICHE",
      bowlingReservation: "RESERVA DE BOLICHE",
      bowlingOnOff: "BOLICHE ON/OFF",
      bowlingBar: "BAR DE BOLICHE",
      parkingConcentrator: "CONCENTRADOR DE ESTACIONAMIENTO",
      mobilePointOfSale: "PUNTO DE VENTA MÓVIL",
      handheld: "DISPOSITIVO PORTÁTIL",
      other: "Otro",
    },
    cardAction: {
      read: "Leer datos",
      write: "Escribir datos",
      auth: "Autenticar sector",
    },
    cardData: {
      cardNotFormatted: "Tarjeta no formateada",
    },
    keyType: {
      A: "Usar clave A",
      B: "Usar clave B",
    },
    cardDocumentType: {
      cpf: "Tarjeta activa con CPF",
      free: "Tarjeta activa sin documento",
    },
    movType: {
      activation: "Adquisición de tarjeta",
      recharge: "Recarga cashless",
      cashlessSale: "Consumo cashless",
      tokenSale: "Emisión de ficha",
      onlineRecharge: "Recarga online",
      cardReplacement: "Reemplazo de tarjeta",
      cashSupply: "Suministro de caja",
      cashWithdrawal: "Retiro de caja",
      refund: "Reembolso",
      balanceTransfer: "Transferencia de saldo",
      tokenReturn: "Devolución de ficha",
      cardBlock: "Bloqueo de tarjeta",
      onlineRefund: "Reembolso online",
      other: "-",
    },
    paymentStatus: {
      success: "Éxito",
      canceled: "Cancelado",
      pending: "Pendiente",
      reversed: "Revertido",
      available: "Disponible",
      reserved: "Reservado",
    },
    paymentType: {
      recharge: "Recarga",
      cardAcquisition: "Adquisición de tarjeta",
      cardReturn: "Devolución de activación",
      refundedBalance: "Reembolso de saldo",
      consumption: "Consumo",
      tokenIssuance: "Emisión de ficha",
      tokenRedemption: "Redención de ficha",
      refundInProgress: "Reembolso en progreso",
      cancelledRefund: "Reembolso cancelado",
      refundFee: "Tarifa de reembolso",
      productRefund: "Reembolso de producto",
      balanceTransfer: "Transferencia de saldo",
      postPaidConsumption: "Consumo pospago",
      paymentRefund: "Reembolso de pago",
      cardReuse: "Reutilización de tarjeta",
      withdrawalTransfer: "Transferencia (retiro)",
      applicationTransfer: "Transferencia (aplicación)",
      tokenReturn: "Devolución de ficha",
      cashlessReprint: "Reimpresión cashless",
      tokenReprint: "Reimpresión de ficha",
      cardBlockRegistration: "Registro de bloqueo de tarjeta",
      cashlessConsumptionRefund: "Reembolso de consumo cashless",
      fractioningOperation: "Operación de fraccionamiento",
      cashboxOpening: "Apertura de caja",
      cashWithdrawal: "Retiro de caja",
      cashSupply: "Suministro de caja",
      cashboxClosing: "Cierre de caja",
      cashBreakage: "Ruptura de caja",
      cashSurplus: "Excedente de caja",
      cashboxClosure: "Cierre de caja",
      feeTransfer: "Transferencia de tarifa",
      rentalFee: "Tarifa de alquiler",
      minimumFee: "Tarifa mínima",
      technicalHourFee: "Tarifa por hora técnica",
      serviceFee: "Tarifa de servicio",
      discount: "Descuento",
      rechargeRefund: "Reembolso de recarga",
      default: "Pago",
    },
    paymentMethod: {
      antecipado: "Anticipado",
      "-": "-",
      cortesia: "Cortesía",
      credito: "Crédito",
      ficha: "Ficha",
      dinheiro: "Efectivo",
      pix: "Pix",
      debito: "Débito",
      brinde: "Regalo",
      cashless: "Cashless",
      banricompras: "Banricompras",
      credito_parcelado: "Crédito en Cuotas",
      bonus: "Bono",
      recarga_online: "Recarga en Línea",
      outros: "Otros",
    },
    productObservation: {
      yes: "Sí",
      optional: "Opcional",
      no: "No",
    },
    refundSituation: {
      finished: "Reembolso completado",
      waitingForConfirmation: "Reembolso esperando confirmación",
      inProgress: "Reembolso en progreso",
      incorrectData: "Datos de reembolso incorrectos",
      rejected: "Reembolso rechazado",
    },
    withdrawalStatus: {
      pending: "Pendiente",
      approved: "Aprobado",
      cancelled: "Cancelado",
      rejected: "Rechazado",
      waitingForAuthentication: "Esperando autenticación",
    },
    transactionStatus: {
      success: "Éxito",
      canceled: "Cancelado",
      pending: "Pendiente",
      reversed: "Revertido",
      available: "Disponible",
      reserved: "Reservado",
    },
    transactionType: {
      recharge: "Recarga",
      cashlessActivation: "Activación Cashless",
      cashlessActivationReturn: "Devolución de Activación Cashless",
      cashlessBalanceReturn: "Devolución de Saldo Cashless",
      consumption: "Consumo",
      tokenIssuance: "Emisión de Fichas",
      tokenRedemption: "Redención de Ficha",
      onlineRefund: "Reembolso Online",
      cancelledRefund: "Reembolso Cancelado",
      refundFee: "Tarifa de Reembolso",
      productRefund: "Reembolso de Producto",
      balanceTransfer: "Transferencia de Saldo",
      postPaidConsumption: "Consumo Pospago",
      paymentRefund: "Reembolso de Pago",
      cardReuse: "Reutilización de Tarjeta",
      withdrawalTransfer: "Transferencia (Retiro)",
      applicationTransfer: "Transferencia (Aplicación)",
      tokenReturn: "Devolución de Fichas",
      cashlessReprint: "Reimpresión Cashless",
      tokenReprint: "Reimpresión de Fichas",
      cardBlockRegistration: "Registro de Bloqueo de Tarjeta",
      cashlessConsumptionRefund: "Reembolso de Consumo Cashless",
      fractioningOperation: "Operación de Fraccionamiento",
      cashboxOpening: "Apertura de Caja",
      cashWithdrawal: "Retiro de Caja",
      cashSupply: "Suministro de Caja",
      cashboxClosing: "Cierre de Caja",
      cashBreakage: "Ruptura de Caja",
      cashSurplus: "Excedente de Caja",
      cashboxClosure: "Cierre de Caja",
      feeTransfer: "Transferencia de Tarifa",
      rentalFee: "Tarifa de Alquiler",
      minimumFee: "Tarifa Mínima",
      technicalHourFee: "Tarifa por Hora Técnica",
      serviceFee: "Tarifa de Servicio",
      discount: "Descuento",
      rechargeRefund: "Reembolso de Recarga",
    },
    useRole: {
      eleven: "ELEVEN",
      imply: "IMPLY",
      representative: "REPRESENTANTE",
      technician: "TÉCNICO",
      productionAdmin: "ADMINISTRADOR DE PRODUCCIÓN",
      operationAdmin: "ADMINISTRADOR DE OPERACIONES",
      attendant: "ATENDENTE",
      other: "-",
    },
    documentType: {
      cpf: "CPF",
      passport: "Pasaporte",
      foreign: "Extranjero",
      other: "-",
    },
  },
};

export default es;
